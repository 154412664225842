import { Button, Flex, DatePicker, Input, message, Avatar, Row, Typography, Tooltip } from "antd";
import { useState, useRef, useEffect, useCallback } from "react";
import { ConfirmButtons, Loader, Modal } from "src/components/ui";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { SvgIcon } from "src/shared/icons";
import moment from "moment";
import { debounce } from "lodash";
import Speedometer from "src/components/pages/prospect-data/ui/ExploreTable/ui/Speedometer";
import { userSelector } from "src/store/user";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredLeadsRst, searchForContact } from "src/store/outreach-campaigns/asyncRequests";
import { outreachCampaignSelector, resetSearchWord } from "src/store/outreach-campaigns";

export const DealPopUp = ({ dealToEdit, onCancel, updateDeal }) => {
  const dispatch = useDispatch();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editingProspectConn, setEditingProspectConn] = useState(false);
  const [prospectConnectedArr, setProspectConnectedArr] = useState(dealToEdit?.sales_pros_connected || []);
  // const [prospectConnectedArr, setProspectConnectedArr] = useState({});
  const [isEditingCloseTime, setIsEditingCloseTime] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [isEditingCloseLikelihood, setIsEditingCloseLikelihood] = useState(false);
  const [closeLikelihoodAmnt, setCloseLikelihoodAmnt] = useState(dealToEdit?.how_good_is_it);
  const [isEditingAmnt, setIsEditingAmnt] = useState(false);
  const [closeAmnt, setCloseAmnt] = useState(dealToEdit?.estimated_money_value);
  const [title, setTitle] = useState(dealToEdit?.title || "");
  const [editingNotes, setEditingNotes] = useState(false);
  const [notes, setNotes] = useState(dealToEdit?.deal_activity || []);
  const [searchContact, setSearchContact] = useState("");

  const { leads, loadingLeads, searchRes } = useSelector(outreachCampaignSelector);
  // useEffect(() => {
  //   if (!dealToEdit?.deal_activity?.length) {
  //     setNotes([]);
  //     return;
  //   }

  // const randomBackgroundColors = [
  //   "#FF6633",
  //   "#FFB399",
  //   "#FF33FF",
  //   "#FFFF99",
  //   "#00B3E6",
  //   "#E6B333",
  //   "#3366E6",
  //   "#999966",
  //   "#99FF99",
  //   "#B34D4D",
  //   "#80B300",
  //   "#809900",
  //   "#E6B3B3",
  //   "#6680B3",
  //   "#66991A",
  //   "#FF99E6",
  //   "#CCFF1A",
  //   "#FF1A66",
  //   "#E6331A",
  //   "#33FFCC",
  // ];

  // dealToEdit?.deal_activity?.map((note) => {});
  // }, [dealToEdit?.deal_activity]);
  const debouncedSearch = useCallback(
    debounce((search) => {
      console.log("dsfdsf");
      if (search?.trim()?.length) {
        dispatch(searchForContact({ search }));
      }
    }, 1500),
    []
  );
  const [searchedPros, setSearchedPros] = useState(false);
  const [showRes, setShowRes] = useState(false);
  const [canShowRes, setCanShowRes] = useState(false);

  useEffect(() => {
    if (searchedPros) {
      // setTimeout(() => {
      setShowRes(true);
      // }, 2000);
    }
    if (showRes && !loadingLeads) {
      // setTimeout(() => {
      setCanShowRes(true);
      // }, 1500);
    }
  }, [searchedPros, loadingLeads]);

  const handleChange = (e) => {
    setSearchedPros(true);
    const value = e.target.value;
    setSearchContact(value);
    debouncedSearch(value);
  };

  const [newNote, setNewNote] = useState("");
  const [delNoteId, setDelNoteId] = useState(null);
  const [delProsConn, setDelProsConn] = useState(null);
  const [description, setDescription] = useState(dealToEdit?.descriptions || "");
  const [lastSavedTitle, setLastSavedTitle] = useState(dealToEdit?.title || "");
  const [lastSavedDescription, setLastSavedDescription] = useState(dealToEdit?.descriptions || "");
  const [estimatedCloseTime, setEstimatedCloseTime] = useState(dealToEdit?.estimated_close_time_unix || null);
  const modalRef = useRef(null);

  // const myRandColor = "#33FFCC";

  const updFEData = () => {
    message.info("Updating data...");
  };

  useEffect(() => {
    setTitle(dealToEdit?.title || "");
    setDescription(dealToEdit?.descriptions || "");
    setLastSavedTitle(dealToEdit?.title || "");
    setLastSavedDescription(dealToEdit?.descriptions || "");
    setEstimatedCloseTime(dealToEdit?.estimated_close_time_unix || null);
  }, [dealToEdit]);

  const handleDiscardNewNote = () => {
    setNewNote("");
    setEditingNotes(false);
  };
  const handleSaveNewNote = () => {
    const newNotes = [...notes, { id: uuidv4(), type: "note", text: newNote, unix_added: dayjs().unix(), author: "ME" }];
    setNotes(newNotes);
    setNewNote("");
    // setTimeout(() => {
    saveInput();
    // }, 1000);
    // setEditingNotes(false);
  };

  const handleSaveTitle = () => {
    setLastSavedTitle(title);
    setIsEditingTitle(false);
    // setTimeout(() => {
    saveInput();
    // }, 1000);
  };

  const handleDiscardTitle = () => {
    setTitle(lastSavedTitle);
    setIsEditingTitle(false);
  };

  const handleSaveDescription = () => {
    setLastSavedDescription(description);
    setIsEditingDescription(false);
    // setTimeout(() => {
    saveInput();
    // }, 1000);
  };

  const handleConnectProsp = (pros) => {
    setProspectConnectedArr((prev) => [...prev, pros]);
    // setEditingProspectConn(false);
  };

  const handleDiscardProsConn = () => {
    setProspectConnectedArr(dealToEdit?.sales_pros_connected?.[0] || {});

    setEditingProspectConn(false);
    // setDescription(lastSavedDescription);
  };

  const handleDiscardDescription = () => {
    setDescription(lastSavedDescription);
    setIsEditingDescription(false);
  };
  let {
    first_name,
    last_name,
    icon,
    id: userId,
    split_between_prosp_to_personalize,
    new_prospectdata_or_personalized_email_permonth,
    ai_personalized_emails_left,
    prospect_data_credits,
    new_payment_status,
    trial_credits,
  } = useSelector(userSelector);

  // const handleSaveLikelihood = (date) => {
  //   // console.log(date, "estimatedCloseTimedate");
  //   setEstimatedCloseTime(date ? dayjs(date).unix() : null);
  //   setIsEditingCloseLikelihood(false);
  // };

  const handleDiscardLikelihood = () => {
    // console.log("estimatedCloseTimedate2");
    setCloseLikelihoodAmnt(dealToEdit?.how_good_is_it);
    setIsEditingCloseLikelihood(false);
    saveInput();
  };

  const handleSaveCloseTime = (date) => {
    // console.log(date, "estimatedCloseTimedate");
    setEstimatedCloseTime(date ? dayjs(date).unix() : null);
    setIsEditingCloseTime(false);
  };

  const handleDiscardCloseTime = () => {
    // console.log("estimatedCloseTimedate2");
    setIsEditingCloseTime(false);
    saveInput();
  };
  const [speedometerVal, setSpeedometerVal] = useState(1);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 200);
  }, []);

  // the below is if wanted to stop chagnes if clicked outside

  // const handleClickOutside = (e) => {
  //   if (modalRef.current && !modalRef?.current?.contains(e.target) && !isEditingCloseTime) {
  //     // console.log("estimatedCloseTimedate3");
  //     setIsEditingTitle(false);
  //     setIsEditingDescription(false);
  //     setIsEditingCloseTime(false);
  //     setTitle(lastSavedTitle);
  //     setDescription(lastSavedDescription);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const hoverStyle = {
    cursor: "pointer",
    padding: "5px",
    borderRadius: "5px",
    transition: "background-color 0.2s",
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.backgroundColor = "#f5f5f5";
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.backgroundColor = "transparent";
  };

  // console.log(estimatedCloseTime, "estimatedCloseTime");

  const saveInput = () => {
    updateDeal({
      title,
      descriptions: description,
      deal_activity: notes,
      sales_pros_connected: prospectConnectedArr,
      estimated_money_value: closeAmnt,
      estimated_close_time_unix: estimatedCloseTime,
      how_good_is_it: +(closeLikelihoodAmnt || 0),
      order: dealToEdit?.order,
      id: dealToEdit?.id,
    });
  };

  const closeThis = () => {
    saveInput();
    if (searchedPros) {
      dispatch(
        getFilteredLeadsRst({
          uploadName: "All",
          recentlyUpdated: false,
          includeInCampaign: false,
          includeContactedAlready: false,
          page: 1,
          pageChange: false,
          selectedCampaignId: null,
        })
      );
    }
    onCancel();
  };

  return (
    <>
      <Modal
        title="Confirm Removal"
        open={!!delProsConn}
        // onOk={() => {

        // }}
        onCancel={() => setDelProsConn(null)}
        zIndex={2000} // Ensure this modal is above the other one
      >
        <p>Remove prospect from deal?</p>
        {/* <p style={{ fontSize: "12px", color: "#888" }}>This will allow their "can't be contacted" status.</p> */}
        <br />
        <ConfirmButtons
          className={"confirm-buttons"}
          leftButtonTitle={"Cancel"}
          onCancel={() => setDelProsConn(null)}
          rightButtonTitle={"Remove"}
          handleSubmit={() => {
            setProspectConnectedArr(prospectConnectedArr.filter((pros) => pros.id !== delProsConn));
            setDelProsConn(null);
            message.success("Prospect removed from deal");
            // setTimeout(() => {
            saveInput();
            // }, 1000);
          }}
        />
      </Modal>
      <Modal
        title="Confirm Deletion"
        open={!!delNoteId}
        // onOk={() => {

        // }}
        onCancel={() => setDelNoteId(null)}
        zIndex={2000} // Ensure this modal is above the other one
      >
        <p>Are you sure you want to delete this note?</p> <br />
        <ConfirmButtons
          className={"confirm-buttons"}
          leftButtonTitle={"Cancel"}
          onCancel={() => setDelNoteId(null)}
          rightButtonTitle={"Delete"}
          handleSubmit={() => {
            setNotes(notes.filter((note) => note.id !== delNoteId));
            setDelNoteId(null);
            message.success("Note deleted");
            // setTimeout(() => {
            saveInput();
            // }, 1000);
          }}
        />
      </Modal>
      <Modal onCancel={closeThis} open={!!dealToEdit} title="Deal Details">
        <div ref={modalRef}>
          <div style={{ marginBottom: "20px" }}>
            <Flex justify="space-between" align="center">
              <Flex align="center" style={{ fontWeight: 600, fontSize: "16px" }}>
                <SvgIcon type={"companyInfo"} width={20} height={20} style={{ marginRight: "10px" }} /> Title
              </Flex>
              {!isEditingTitle && (
                <Button className="link" onClick={() => setIsEditingTitle(true)}>
                  Edit
                </Button>
              )}
            </Flex>
            {isEditingTitle ? (
              <Input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{
                  // width: "100%",
                  padding: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  marginTop: "8px",
                  marginLeft: "30px",
                  width: "420px",
                }}
              />
            ) : (
              <div
                style={{ marginLeft: "32px", fontSize: "14px", ...hoverStyle }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => setIsEditingTitle(true)}
              >
                {title}
              </div>
            )}{" "}
            {!!isEditingTitle && (
              <Flex justify="flex-end" style={{ marginTop: "8px" }} align="center">
                <Button onClick={handleSaveTitle} style={{ marginRight: "8px" }} className="primary">
                  Save
                </Button>
                <Button onClick={handleDiscardTitle} className="link">
                  Discard changes
                </Button>
              </Flex>
            )}
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Flex justify="space-between" align="center">
              <Flex align="center" style={{ fontWeight: 600, fontSize: "16px" }}>
                <SvgIcon type={"ListIcon"} width={30} height={30} /> Description
              </Flex>
              {!isEditingDescription && (
                <Button className="link" onClick={() => setIsEditingDescription(true)}>
                  Edit
                </Button>
              )}
            </Flex>
            {isEditingDescription ? (
              <Input.TextArea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                autoSize={{ minRows: 2, maxRows: 5 }}
                style={{
                  // width: "100%",
                  padding: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  marginTop: "8px",
                  marginLeft: "30px",
                  width: "420px",
                }}
              />
            ) : (
              <div
                style={{ marginLeft: "32px", fontSize: "14px", ...hoverStyle }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => setIsEditingDescription(true)}
              >
                {description}
              </div>
            )}{" "}
            {!!isEditingDescription && (
              <Flex justify="flex-end" style={{ marginTop: "8px" }} align="center">
                <Button onClick={handleSaveDescription} style={{ marginRight: "8px" }} className="primary">
                  Save
                </Button>
                <Button onClick={handleDiscardDescription} className="link">
                  Discard changes
                </Button>
              </Flex>
            )}
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Flex justify="space-between" align="center">
              <Flex align="center" style={{ fontWeight: 600, fontSize: "16px" }}>
                <SvgIcon type={"likeLinkedIn"} width={22} height={22} style={{ marginRight: "8px" }} /> Notes
              </Flex>

              <Button className="link" onClick={() => setEditingNotes((prev) => !prev)}>
                {editingNotes ? "Close View" : "View"}
              </Button>
            </Flex>
            {editingNotes ? (
              <>
                {notes?.map((note) => {
                  const iconToUse = note?.author === "ME" ? icon : note?.author?.icon;
                  const fName = note?.author === "ME" ? first_name : note?.author?.first_name;
                  const lName = note?.author === "ME" ? last_name : note?.author?.last_name;

                  const isMe = note?.author === "ME" || note?.author?.id === userId;

                  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
                  return (
                    <div
                      key={note.id}
                      style={{
                        marginLeft: "32px",
                        marginTop: "8px",
                        padding: "10px",
                        border: "1px solid #e0e0e0",
                        borderRadius: "8px",
                        backgroundColor: "#fafafa",
                      }}
                    >
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {" "}
                        <Flex align={"center"} gap={8}>
                          <Flex className={"header-avatar-wrapper"} style={{ width: 40, marginRight: -7 }}>
                            <Avatar
                              className={"header-avatar"}
                              size={"large"}
                              src={iconToUse}
                              style={{
                                width: 25,
                                height: 25,
                                backgroundColor: iconToUse ? "#757A891A" : `#${Math.floor(Math.random() * 16777215).toString(16)}`, // later add random color for every client
                                border: "unset",
                              }}
                            >
                              {!iconToUse && `${fName?.charAt(0) || ""}${lName?.charAt(0) || ""}`}
                            </Avatar>
                          </Flex>
                          <div style={{ height: "fit-content", width: "fit-content" }}> {`${fName || ""} ${lName || ""}`}</div>
                          <div style={{ fontSize: "12px", color: "#888", height: "fit-content", marginTop: "3px" }}>
                            Added {moment.unix(note.unix_added).tz(moment.tz.guess()).format("MMM Do [']YY, h:mm A z")}
                          </div>
                        </Flex>
                        <div>
                          {!!isMe && (
                            <Button
                              className="link"
                              onClick={() => {
                                setDelNoteId(note.id);
                                // Modal.confirm({
                                //   title: "Confirm Deletion",
                                //   content: "Are you sure you want to delete this note?",
                                //   onOk: () => {
                                //     setNotes(notes.filter((n) => n.id !== note.id));
                                //   },
                                // });
                                // setTimeout(() => {
                                saveInput();
                                // }, 1000);
                              }}
                              style={{ marginRight: "8px" }}
                            >
                              Delete
                            </Button>
                          )}
                          {/* {   <Button className="link" onClick={() => setNewNote(note.text)}>
                            Edit
                          </Button>} */}
                        </div>
                      </div>
                      <div style={{ marginTop: "8px" }}>{note.text}</div>
                    </div>
                  );
                })}
                <Input.TextArea
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  style={{
                    // width: "100%",
                    padding: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    marginTop: "8px",
                    marginLeft: "30px",
                    width: "420px",
                  }}
                />
              </>
            ) : (
              <div
                style={{ marginLeft: "25px", fontSize: "14px", ...hoverStyle }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => setEditingNotes(true)}
              >
                {notes?.length || 0} note{notes?.length !== 1 ? "s" : ""} added
              </div>
            )}{" "}
            {!!editingNotes && (
              <Flex justify="flex-end" style={{ marginTop: "8px" }} align="center">
                <Button onClick={() => !!newNote?.length && handleSaveNewNote()} style={{ marginRight: "8px" }} className="primary">
                  Save
                </Button>
                <Button onClick={handleDiscardNewNote} className="link">
                  Discard
                </Button>
              </Flex>
            )}
          </div>
          {!!showMoreDetails && (
            <>
              <div style={{ marginBottom: "20px" }}>
                <Flex justify="space-between" align="center">
                  <Flex align="center" style={{ fontWeight: 600, fontSize: "16px" }}>
                    <SvgIcon type={"contactInfo"} width={22} height={22} style={{ marginRight: "8px" }} /> Prospects connected to this deal{" "}
                    <Tooltip
                      title={
                        <>
                          Connect prospects to this deal. <br />
                          <br />
                          This will also remove the prospect from any campaign in order to avoid any automated follow ups.
                        </>
                      }
                    >
                      <SvgIcon type={"tooltip"} width={20} height={20} style={{ marginLeft: "5px" }} />
                    </Tooltip>
                  </Flex>

                  <Button
                    className="link"
                    onClick={() => {
                      setEditingProspectConn((prev) => !prev);
                      if (!editingProspectConn) {
                        setSearchContact("");
                        debouncedSearch("");
                      }
                    }}
                  >
                    {!editingProspectConn ? (prospectConnectedArr?.length ? "View" : "Edit") : "Close"}
                  </Button>
                </Flex>{" "}
                {editingProspectConn ? (
                  <Flex vertical>
                    <div style={{ marginLeft: "20px", fontSize: "14px", marginTop: "10px" }}>
                      {!!prospectConnectedArr?.length &&
                        prospectConnectedArr?.map((pros, i) => {
                          return (
                            <div
                              key={pros.id}
                              style={{
                                display: "flex",
                                // flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "center",
                                margin: "5px 0px",
                                height: "fit-content",
                                backgroundColor: i % 2 === 0 ? "#fcfcfc" : "#f7f7f7",
                                borderRadius: "8px",
                                padding: "10px",
                                border: "1px solid #dcdcdc",
                              }}
                            >
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  // alignItems: "center",
                                  // marginBottom: "10px",
                                  height: "fit-content",
                                }}
                              >
                                <strong>Name:</strong> {pros?.first_name || ""} {pros?.last_name || ""}
                                <br /> <strong>Email:</strong> {pros?.email || ""}
                                {!!pros?.title && (
                                  <>
                                    <br />
                                    <strong>Job Title:</strong> {pros?.title}
                                  </>
                                )}
                                {!!pros?.company_name && (
                                  <>
                                    <br />
                                    <strong>Company:</strong> {pros?.company_name}
                                  </>
                                )}
                              </div>
                              <Button
                                className="link"
                                onClick={() => {
                                  setDelProsConn(pros.id);
                                }}
                                style={{ marginLeft: "10px" }}
                              >
                                Delete
                              </Button>
                            </div>
                          );
                        })}
                    </div>
                    <Input.Search
                      className="search-input"
                      placeholder="Search for name, title, or company..."
                      allowClear
                      onChange={handleChange}
                      enterButton={false}
                      style={{
                        maxWidth: "400px",
                        marginLeft: "25px",
                        marginTop: "20px",
                        borderRadius: "8px",
                        // border: "1px solid #d9d9d9",
                        padding: "5px 10px",
                      }}
                      value={searchContact}
                    />
                    <div
                      style={{
                        // backgroundColor: "#ffffff",
                        // boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        borderRadius: "10px",
                        marginTop: "5px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "120px",
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {!!loadingLeads ? (
                        <>
                          {" "}
                          <div>
                            {" "}
                            <Loader size={"small"} style={{ marginLeft: "20px" }} />
                          </div>
                          <div> Searching</div>
                        </>
                      ) : (
                        " "
                      )}
                    </div>
                    {!!searchedPros && !!searchContact && !!searchRes?.length && (
                      <div style={{ marginTop: "5px", marginLeft: "20px", maxWidth: "420px" }}>
                        {leads
                          ?.filter((ld) => !prospectConnectedArr?.some((conPros) => conPros?.id === ld?.id))
                          ?.slice(0, 5)
                          ?.map((prospect) => (
                            <div
                              key={prospect.id}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "15px",
                                padding: "15px",
                                border: "1px solid #e0e0e0",
                                width: "420px",
                                borderRadius: "10px",
                                backgroundColor: "#ffffff",
                                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                transition: "transform 0.2s, box-shadow 0.2s",
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.transform = "scale(1.02)";
                                e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.2)";
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.transform = "scale(1)";
                                e.currentTarget.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.1)";
                              }}
                            >
                              <div>
                                <div style={{ fontWeight: "600", fontSize: "16px", color: "#333" }}>
                                  {prospect.first_name || ""} {prospect.last_name || ""}
                                </div>
                                <div style={{ fontSize: "14px", color: "#555" }}>{!!prospect.email && `Email: ${prospect.email}`}</div>
                                <div style={{ fontSize: "14px", color: "#555" }}>{!!prospect.company_name && `Company: ${prospect.company_name}`}</div>
                                <div style={{ fontSize: "14px", color: "#555" }}>{!!prospect.title && `Job Title: ${prospect.title}`}</div>
                              </div>
                              <Button
                                className="primary"
                                // style={{
                                //   borderRadius: "5px",
                                //   fontWeight: "500",
                                //   padding: "5px 15px",
                                //   backgroundColor: "#1677ff",
                                //   borderColor: "#999",
                                // }}
                                onClick={() => {
                                  handleConnectProsp(prospect);
                                  // setEditingProspectConn(false);
                                  setShowRes(false);
                                  setSearchedPros(false);
                                  dispatch(resetSearchWord());
                                  setSearchContact("");
                                  debouncedSearch("");
                                  message.success("Prospect added to the deal");
                                  //  setTimeout(() => {
                                  saveInput();
                                  //  }, 1000);
                                }}
                              >
                                Connect
                              </Button>
                            </div>
                          ))}
                      </div>
                    )}
                  </Flex>
                ) : (
                  <div
                    style={{ marginLeft: "32px", fontSize: "14px", ...hoverStyle }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => setEditingProspectConn(true)}
                  >
                    {prospectConnectedArr?.length
                      ? `${prospectConnectedArr?.length || 0} prospect${prospectConnectedArr?.length !== 1 ? "s" : ""} connected`
                      : "No prospects connected"}
                  </div>
                )}{" "}
                {/* {!!editingProspectConn && (
                  
                )} */}
              </div>
              <div style={{ marginBottom: "20px" }}>
                <Flex justify="space-between" align="center">
                  <Flex align="center" style={{ fontWeight: 600, fontSize: "16px" }}>
                    <SvgIcon type={"growth"} width={20} height={20} style={{ marginRight: "10px" }} />
                    Estimated deal size
                  </Flex>
                  {!isEditingAmnt && (
                    <Button className="link" onClick={() => setIsEditingAmnt(true)}>
                      Edit
                    </Button>
                  )}
                </Flex>{" "}
                {!!isEditingAmnt && (
                  <Flex align="center">
                    {/* <div style={{ height: "10px", width: "10px" }}> */}
                    {/* </div> */}
                    <div style={{ marginLeft: "30px", marginTop: "8px" }}>
                      <Input
                        // type="number"
                        value={closeAmnt || 0}
                        onChange={(e) => !isNaN(Number(e.target.value)) && setCloseAmnt(Number(e.target.value))}
                        style={{
                          width: "100px",
                          padding: "5px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          appearance: "none",
                          MozAppearance: "textfield",
                          WebkitAppearance: "none",
                        }}
                        inputMode="numeric"
                        // showCount={false}
                        // count={false}
                      />
                      <Button
                        onClick={() => {
                          setIsEditingAmnt(false);
                          saveInput();
                        }}
                        style={{ marginLeft: "8px", marginTop: "8px" }}
                        className="primary"
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          setCloseAmnt(dealToEdit?.estimated_money_value);
                          setIsEditingAmnt(false);
                          saveInput();
                        }}
                        style={{ marginLeft: "8px", marginTop: "8px" }}
                        className="link"
                      >
                        Discard changes
                      </Button>
                    </div>
                  </Flex>
                )}
                {!isEditingAmnt && (
                  <div style={{ marginLeft: "32px", fontSize: "14px", ...hoverStyle }} onClick={() => setIsEditingAmnt(true)}>
                    ${closeAmnt?.toLocaleString()}
                  </div>
                )}
              </div>
              <div style={{ marginBottom: "20px" }}>
                <Flex justify="space-between" align="center">
                  <Flex align="center" style={{ fontWeight: 600, fontSize: "16px" }}>
                    <SvgIcon type={"calendar2"} width={20} height={20} style={{ marginRight: "10px" }} /> Estimated close time
                  </Flex>
                  {!isEditingCloseTime && (
                    <Button className="link" onClick={() => setIsEditingCloseTime(true)}>
                      Edit
                    </Button>
                  )}
                </Flex>{" "}
                {!!isEditingCloseTime && (
                  <>
                    <div>
                      <DatePicker
                        value={estimatedCloseTime ? dayjs.unix(estimatedCloseTime) : dayjs()}
                        onChange={(date) => handleSaveCloseTime(date)}
                        style={{ marginTop: "2px", marginLeft: "30px" }}
                        format="MMMM Do YYYY"
                      />
                      {/* <Flex> */}
                      <Button onClick={handleDiscardCloseTime} style={{ marginLeft: "8px" }} className="primary">
                        Save
                      </Button>
                      <Button onClick={handleDiscardCloseTime} style={{ marginLeft: "8px" }} className="link">
                        Discard changes
                      </Button>
                      {/* <Button onClick={handleDiscardCloseTime} style={{ marginLeft: "8px" }}>
                    Remove Date
                  </Button>
                </Flex> */}
                    </div>
                  </>
                )}
                {!isEditingCloseTime && (
                  <div
                    style={{ marginLeft: "32px", fontSize: "14px", ...hoverStyle }}
                    // onMouseEnter={handleMouseEnter}
                    // onMouseLeave={handleMouseLeave}
                    onClick={() => setIsEditingCloseTime(true)}
                  >
                    {estimatedCloseTime ? dayjs.unix(estimatedCloseTime).format("MMMM Do YYYY") : "No date set"}
                  </div>
                )}
              </div>
              <div style={{ marginBottom: "20px" }}>
                <Flex justify="space-between" align="center">
                  <Flex align="center" style={{ fontWeight: 600, fontSize: "16px" }}>
                    {/* <SvgIcon type={"speedometer"} width={20} height={20} style={{ marginRight: "10px" }} />  */}
                    <Speedometer value={closeLikelihoodAmnt / 10} animate={false} small />
                    Likely to close
                  </Flex>
                  {!isEditingCloseLikelihood && (
                    <Button className="link" onClick={() => setIsEditingCloseLikelihood(true)}>
                      Edit
                    </Button>
                  )}
                </Flex>{" "}
                {!!isEditingCloseLikelihood && (
                  <Flex align="center">
                    {/* <div style={{ height: "10px", width: "10px" }}> */}
                    {/* </div> */}
                    <div style={{ marginLeft: "30px", marginTop: "8px" }}>
                      <input
                        type="range"
                        min="0"
                        max="10"
                        value={closeLikelihoodAmnt || 1}
                        onChange={(e) => setCloseLikelihoodAmnt(e.target.value)}
                        style={{ width: "100%" }}
                      />
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>0%</span>
                        <span>100%</span>
                      </div>
                      <Button
                        onClick={() => {
                          setIsEditingCloseLikelihood(false);
                          saveInput();
                        }}
                        style={{ marginLeft: "8px" }}
                        className="primary"
                      >
                        Save
                      </Button>
                      <Button onClick={handleDiscardLikelihood} style={{ marginLeft: "8px" }} className="link">
                        Discard changes
                      </Button>
                    </div>
                  </Flex>
                )}
                {!isEditingCloseLikelihood && (
                  <div style={{ marginLeft: "32px", fontSize: "14px", ...hoverStyle }} onClick={() => setIsEditingCloseLikelihood(true)}>
                    {+closeLikelihoodAmnt || ""}0%
                  </div>
                )}
              </div>{" "}
            </>
          )}
          <div style={{ marginTop: "20px" }}>
            <Button onClick={() => setShowMoreDetails(!showMoreDetails)} className="link">
              {showMoreDetails ? "Show less" : "Show more"}
            </Button>
          </div>

          <div style={{ marginTop: "20px", fontSize: "12px", color: "#888" }}>
            {dealToEdit?.unix_added ? <>Added: {moment.unix(dealToEdit?.unix_added).format("MMMM Do YYYY, h:mm A")}</> : "New deal added now"}
          </div>
        </div>
      </Modal>
    </>
  );
};
