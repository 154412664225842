import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandler } from "../helpers/apiHandler";

export const generateAiHooks = createAsyncThunk("outreachCampaign/generateAiHooks", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/generateAiHooks",
    method: "post",
    body,
  });
  return res;
});

export const updateDealsBackend = createAsyncThunk("outreachCampaign/updateDealsBackend", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/updateDealsBackend",
    method: "post",
    body,
  });
  return res;
});

export const searchForContact = createAsyncThunk("outreachCampaign/searchForContact", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/searchForContact",
    method: "post",
    body,
  });
  return res;
});

export const getEachStepsAnalytics = createAsyncThunk("outreachCampaign/getEachStepsAnalytics", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/getEachStepsAnalytics",
    method: "post",
    body,
  });
  return res;
});

export const getCliAiSettings = createAsyncThunk("outreachCampaign/getCliAiSettings", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/getCliAiSettings",
    method: "post",
    // body,
  });
  return res;
});

export const editContactsInAccountAllow = createAsyncThunk(
  "outreachCampaign/editContactsInAccountAllow",
  async (body: any, { getState }: { getState: any }) => {
    const { iframeJwt } = getState().helpers;
    const res = await apiHandler({
      iframeJwt,
      url: "/aiSales/editContactsInAccount",
      method: "post",
      body,
    });
    return res;
  }
);

export const editContactsInAccountStop = createAsyncThunk("outreachCampaign/editContactsInAccount", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/editContactsInAccount",
    method: "post",
    body,
  });
  return res;
});

export const editContactsInAccountCampaign = createAsyncThunk(
  "outreachCampaign/editContactsInAccountCampaign",
  async (body: any, { getState }: { getState: any }) => {
    const { iframeJwt } = getState().helpers;
    const res = await apiHandler({
      iframeJwt,
      url: "/aiSales/editContactsInAccount",
      method: "post",
      body,
    });
    return res;
  }
);

export const editContactsInAccountDelete = createAsyncThunk(
  "outreachCampaign/editContactsInAccountDelete",
  async (body: any, { getState }: { getState: any }) => {
    const { iframeJwt } = getState().helpers;
    const res = await apiHandler({
      iframeJwt,
      url: "/aiSales/editContactsInAccount",
      method: "post",
      body,
    });
    return res;
  }
);

export const delSuppressedList = createAsyncThunk("outreachCampaign/delSuppressedList", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/delSuppressedList",
    method: "post",
    body,
  });

  return res;
});

export const uploadSuppressions = createAsyncThunk("outreachCampaign/uploadSuppressions", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/uploadSuppressions",
    method: "post",
    body,
  });

  return res;
});

export const getSuppressedList = createAsyncThunk("outreachCampaign/getSuppressedList", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/getSuppressedList",
    method: "post",
    body,
  });

  return res;
});

export const updateCampaignSteps = createAsyncThunk("outreachCampaign/updateCampaignSteps", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/updateCampaignSteps",
    method: "post",
    body,
  });

  return res;
});

export const updateCampaignSettings = createAsyncThunk("outreachCampaign/updateCampaignSettings", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/updateCampaignSettings",
    method: "post",
    body,
  });

  return res;
});

export const getEmailGraphAnalytics = createAsyncThunk("outreachCampaign/getEmailGraphAnalytics", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/getEmailGraphAnalytics",
    method: "post",
    body,
  });

  return res;
});

export const addProsToCampaign = createAsyncThunk("outreachCampaign/addProsToCampaign", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/addProsToCampaign",
    method: "post",
    body,
  });

  return res;
});

export const generateOneAiEmail = createAsyncThunk("outreachCampaign/generateOneAiEmail", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/generateOneAiEmail",
    method: "post",
    body,
    longTimer: true,
  });

  return res;
});

export const approveSteps = createAsyncThunk("outreachCampaign/approveSteps", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/approveSteps",
    method: "post",
    body,
  });

  return res;
});

export const viewMoreProsInStep = createAsyncThunk("outreachCampaign/viewMoreProsInStep", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/viewMoreProsInStep",
    method: "post",
    body,
  });

  return res;
});

export const updateTestEmailSettings = createAsyncThunk("outreachCampaign/updateTestEmailSettings", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/updateTestEmail",
    method: "post",
    body: { ...body, returnEmail: false },
  });

  return res;
});

export const getCampaignDetails = createAsyncThunk("outreachCampaign/getCampaignDetails", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/getCampaignDetails",
    method: "post",
    body,
  });

  return res;
});

export const pauseCampaign = createAsyncThunk("outreachCampaign/pauseCampaign", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/pauseCampaign",
    method: "post",
    body,
  });

  return res;
});

export const resetCampIdDiscEmails = createAsyncThunk("outreachCampaign/resetCampIdDiscEmails", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/resetCampIdDiscEmails",
    method: "post",
    body,
  });

  return res;
});

export const editCamps = createAsyncThunk("outreachCampaign/editCamps", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/editCamps",
    method: "post",
    body,
  });

  return res;
});

export const delCamps = createAsyncThunk("outreachCampaign/delCamps", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/editCamps",
    method: "post",
    body,
  });

  return res;
});

export const setCampPageNum = createAsyncThunk("outreachCampaign/setCampPageNum", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/setCampPageNum",
    method: "post",
    body,
  });

  return res;
});

export const getNewABTests = createAsyncThunk("outreachCampaign/getNewABTests", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/getNewABTests",
    method: "post",
    body,
  });

  return res;
});

export const updateTestEmailSettingsWithEmail = createAsyncThunk(
  "outreachCampaign/updateTestEmailSettingsWithEmail",
  async (body: any, { getState }: { getState: any }) => {
    const { iframeJwt } = getState().helpers;
    const res = await apiHandler({
      iframeJwt,
      url: "/aiSales/updateTestEmail",
      method: "post",
      body: { ...body, returnEmail: true },
    });

    return res;
  }
);

export const valuePropUpd = createAsyncThunk("outreachCampaign/valuePropUpd", async (body, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/valuePropUpd",
    method: "post",
    body,
  });

  return res;
});

export const addNewCampaignV2 = createAsyncThunk("outreachCampaign/addNewCampaignV2", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/addNewCampaignV2",
    method: "post",
    body,
    longTimer: true,
  });
  return res;
});

export const autoSave = createAsyncThunk("outreachCampaign/autoSave", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/autoSave",
    method: "post",
    body,
  });
  return res;
});

export const createCampaignStepsWithAi = createAsyncThunk("outreachCampaign/createCampaignStepsWithAi", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/createCampaignStepsWithAi",
    method: "post",
    body,
    longTimer: true,
  });
  return res;
});

export const uploadLeadFileAndColumnInd = createAsyncThunk(
  "outreachCampaign/uploadLeadFileAndColumnInd",
  async (body: any, { getState }: { getState: any }) => {
    const { iframeJwt } = getState().helpers;
    const res = await apiHandler({
      iframeJwt,
      url: "/aiSales/addLeads",
      method: "post",
      body,
    });
    return res;
  }
);

export const getFilteredLeads = createAsyncThunk("outreachCampaign/getFilteredLeads", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/getFilteredLeads",
    method: "post",
    body,
  });
  return res;
});

export const getFilteredLeadsRst = createAsyncThunk("outreachCampaign/getFilteredLeadsRst", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/getFilteredLeads",
    method: "post",
    body,
  });
  return res;
});

// for special requests

export const verifyEmail = createAsyncThunk("aiSales/verifyEmail", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/verifyEmail",
    method: "post",
    body,
  });

  return res;
});

export const unsubscribeMe = createAsyncThunk("aiSales/unsubscribeMe", async (body, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/unsubscribeMe",
    method: "post",
    body,
  });

  return res;
});

export const doNotSell = createAsyncThunk("prospectdata/doNotSell", async (body, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/prospectdata/doNotSell",
    method: "post",
    body,
  });

  return res;
});
