import { DatePicker, Checkbox, Flex, Form, Input, Radio, Space, Typography } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmButtons, Modal } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { AppDispatch } from "src/store";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import { outreachEmailsSelector, setExportInformationToDownload, setExportName, setExportCampsId, setExportUnixRange } from "src/store/outreach-emails";
import "./index.less";
import { downloadEmailAnalytics } from "src/store/outreach-emails/asyncRequests";

export const DownloadEmailAnalytics = ({ open, onClose }) => {
  const { RangePicker } = DatePicker;
  const { informationToDownload, nameOfExportFile, campaignsToDownload, unixdates } = useSelector(outreachEmailsSelector);
  const dispatch = useDispatch();
  const bareOptions = ["Email Sends", "Email Opens", "Email Link Clicks", "Likely Forwarded", "Email Replies", "Booked Meetings"];
  const checkAllBare = bareOptions?.length === informationToDownload?.length;
  const { allCampsList } = useSelector(outreachCampaignSelector);
  const onBareChange = (list) => {
    dispatch(setExportInformationToDownload(list));
  };
  const onCheckAllBareChange = (e) => {
    dispatch(setExportInformationToDownload(e.target.checked ? bareOptions : []));
  };
  const onGetAllCamps = (e) => {
    dispatch(setExportCampsId(e.target.checked ? "all" : []));
  };

  const [allTime, setAllTime] = useState(false);

  const onGetAllDates = (e) => {
    setAllTime(e.target.checked);
  };
  const handleInputChange = (e) => {
    dispatch(setExportName(e.target.value));
  };
  const [searchTerm, setSearchTerm] = useState<string>("");
  const bareIndeterminate = informationToDownload && informationToDownload.length > 0 && informationToDownload.length < bareOptions.length;
  const filteredCampaigns = allCampsList?.filter((item) => item?.campName?.toLowerCase()?.includes(searchTerm?.toLowerCase()))?.slice(0, 3);

  // const [campToCopId, setCampToCopId] = useState<string | undefined>();
  const handleRadioBtnChange = (e: any) => {
    // console.log(e, "campaignsToDownload1", filteredCampaigns);
    const campid = e;
    let isAll = false;
    // console.log(campaignsToDownload, "campaignsToDownload", campid);
    // if (campaignsToDownload === "all") {
    //   // isAll = true;
    //   return;
    // }
    // if (campaignsToDownload){}else{}
    dispatch(setExportCampsId(campid));

    // if (campaignsToDownload?.includes(campid)) {
    //   dispatch(setExportCampsId(campaignsToDownload.filter((id) => id !== campid)));
    // } else {
    //   dispatch(setExportCampsId([...campaignsToDownload, campid]));
    // }
  };
  // const [unixRange, setUnixRange] = useState([null, null]);

  // Limit the date selection range to 1 year back and 1 year forward

  const disabledDate = (current) => {
    const oneYearBack = dayjs().subtract(1, "year");
    const oneYearForward = dayjs().add(1, "year");
    return current && (current.isBefore(oneYearBack) || current.isAfter(oneYearForward));
  };

  const handleDateRangeChange = (dates) => {
    if (dates) {
      // Convert selected dates to Unix timestamps for midnight (local time)
      const unixStartOfDay = dayjs(dates[0]).startOf("day").unix();
      const unixEndOfDay = dayjs(dates[1]).endOf("day").unix();

      dispatch(setExportUnixRange([unixStartOfDay, unixEndOfDay]));
    } else {
      dispatch(setExportUnixRange([null, null]));
    }
  };

  const downlaodAnalytics = (current) => {
    dispatch(downloadEmailAnalytics({ informationToDownload, nameOfExportFile, campaignsToDownload, unixdates, allTime }));
  };
  return (
    <Modal open={open} onCancel={onClose} title={"Download Analytics to Spreadsheet"}>
      <Space direction={"vertical"}>
        <Typography.Title style={{ fontSize: "16px", color: "black", fontWeight: 400 }}>1. Export name</Typography.Title>{" "}
        <Input value={nameOfExportFile} onChange={handleInputChange} placeholder="Enter export name" style={{ marginBottom: "12px" }} />
        <Typography.Title style={{ fontSize: "16px", color: "black", fontWeight: 400 }}>2. Analytics to export</Typography.Title>
        <Checkbox indeterminate={bareIndeterminate} onChange={onCheckAllBareChange} checked={checkAllBare} style={{ fontWeight: 500 }}>
          All
        </Checkbox>
        <Checkbox.Group
          options={bareOptions}
          value={informationToDownload}
          onChange={onBareChange}
          // disabled={checkedPhoneList?.length===plainPhoneOptions?.length}
          style={{ flexDirection: "column", gap: 8, paddingLeft: 28 }}
        />{" "}
      </Space>
      <Flex vertical gap={10} style={{ marginTop: "10px" }}>
        <Typography.Title style={{ fontSize: "16px", color: "black", fontWeight: 400 }}>3. Campaigns to export</Typography.Title>
        <div>
          Selected {campaignsToDownload === "all" ? allCampsList?.length : campaignsToDownload?.length || 0} out of {allCampsList?.length || 0} campaigns you
          have
        </div>
        <Checkbox
          indeterminate={campaignsToDownload?.length && allCampsList?.length !== campaignsToDownload?.length && campaignsToDownload !== "all"}
          onChange={onGetAllCamps}
          checked={campaignsToDownload === "all" || allCampsList?.length === campaignsToDownload?.length}
        >
          Select All Campaigns
        </Checkbox>
        <div>Or</div>
        <Typography.Paragraph>Search for specific campaigns to export</Typography.Paragraph>
        <Input.Search
          className={"search-input"}
          placeholder={"Search previous campaigns name..."}
          allowClear
          prefix={<SvgIcon type={"search"} />}
          enterButton={false}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {/* <Form.Item name={"camptocopy"} rules={[{ required: true, message: "Select campaign to copy" }]} style={{ width: "350px" }}> */}
        <Checkbox.Group value={campaignsToDownload} onChange={handleRadioBtnChange} style={{ padding: "10px" }}>
          <Flex gap={10} vertical>
            {filteredCampaigns?.length ? (
              filteredCampaigns.map((item, index) => (
                <Checkbox key={`${index}-${item.id}`} value={campaignsToDownload === "all" ? "all" : item.id}>
                  {item.campName}
                </Checkbox>
              ))
            ) : (
              <div>No campaign found</div>
            )}
          </Flex>
        </Checkbox.Group>
        {/* </Form.Item> */}
      </Flex>
      <Typography.Title style={{ fontSize: "16px", color: "black", fontWeight: 400, marginTop: "10px", marginBottom: "10px" }}>
        4. Timeframe to export
      </Typography.Title>
      <Checkbox onChange={onGetAllDates} checked={allTime} style={{ marginBottom: "10px" }}>
        Export data from all time
      </Checkbox>
      <br />
      <RangePicker
        className={"time-picker"}
        onChange={handleDateRangeChange}
        disabledDate={disabledDate}
        format="YYYY-MM-DD" // Format as date only
        disabled={allTime}
      />
      <br />
      <br />
      <br />
      <ConfirmButtons
        rightButtonTitle={"Download export"}
        leftButtonTitle={"Back"}
        handleSubmit={downlaodAnalytics}
        className={"skip-trial-wrapper"}
        style={{ margin: "0 0 20px 0" }}
        disabled={(campaignsToDownload !== "all" && !campaignsToDownload?.length) || !informationToDownload?.length}
        disabledExplanation={!informationToDownload?.length ? "Select at least one analytic to export" : "Must have at least 1 campaign selected"}
      />
    </Modal>
  );
};
