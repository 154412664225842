/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Flex, message, Row, Space, Typography } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { Loader } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { aiGeneratedPersonalData } from "../../../campaignsData";
import "./index.less";
import { DialerPopupInfo } from "./ui";
import { AiGeneratedPersonalIntroModal } from "./ui/AiGeneratedPersonalIntroModal";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import {
  activelyOnCall,
  dialerSelector,
  readyToDialSwitch,
  resetMultiDialerPros,
  resetVoiceToken,
  setFinishedReason,
  setIsPopUpOpen,
  setPowerDialer,
  setProsCalled,
  setStopDialing,
  setWhoIsActCompanyOnCall,
  setWhoIsActLinkedInOnCall,
} from "src/store/dialer";
import { stopMultiDialingActive, whoIsActiveContactCompany, whoIsActiveContactLinkedIn } from "src/store/dialer/asyncRequests";
import { TwilioComponent } from "./TwilioComponent";
import { ManageCallBalanceModal } from "src/components/pages/phone-calls/ui/ManageCallBalanceModal";
import { API_BASE } from "src/pages/constant";
import { userSelector } from "src/store/user";
import { outreachCampaignSelector, setDialerCanCallNowInTheirWorkingHours, setDialerCanCallNowInTheirWorkingHoursReduceBy } from "src/store/outreach-campaigns";

interface ParallelDialerModalProps {
  onCancel?: VoidFunction;
}

export const ParallelDialerModal: FC<ParallelDialerModalProps> = ({ onCancel }) => {
  const dispatch = useDispatch();

  // const [openAiGeneratedModal, setOpenAiGeneratedModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialing, setDialing] = useState(false);
  const [paused, setPaused] = useState(false);
  const [noMinutes, setNoMinutes] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [openAiGeneratedModal, setOpenAiGeneratedModal] = useState(false);
  const [messageToShow, setMessageToShow] = useState("");

  // useEffect(() => {
  // const performDialing = async () => {
  //   setLoading(true);
  //   setDialing(false);
  //   await new Promise((resolve) => setTimeout(resolve, 4000));
  //   setLoading(false);
  //   setDialing(true);
  //   await new Promise((resolve) => setTimeout(resolve, 4000));
  //   setDialing(false);
  //   setNoMinutes(true);
  //   await new Promise((resolve) => setTimeout(resolve, 4000));
  //   setNoMinutes(false);
  //   setIsCompleted(true);
  //   await new Promise((resolve) => setTimeout(resolve, 4000));
  //   setIsCompleted(false);
  //   setOpenAiGeneratedModal(true);
  // };
  // performDialing();
  // }, []);

  const [isMinimized, setIsMinimized] = useState(false);
  const [resetNowTwilio, setResetNowTwilio] = useState(true);
  const [callsHappeningNow, setCallsHappeningNow] = useState(0);
  const [deviceUsing, setDeviceUsing] = useState(null);
  const [pauseNow, setPauseNow] = useState(false);
  const [imageErr, setImageErr] = useState([]);
  const handleImageError = (i) => {
    if (imageErr?.length && !imageErr.includes(i)) {
      setImageErr([...imageErr, i]);
    } else {
      setImageErr([i]);
    }
  };
  const [dialerStatus, setDialerStatus] = useState("");

  // remove as much as possible from here except for the ID then slowly return things. Also twilio open a new twilio component
  const {
    firstLoad,
    // callingInProgress,
    dialerPowerDialingOnInfo,
    multiCallingFullyPaused,
    finishedWithProspectsInDialer,
    prosp_called_now,
    amount_of_prosp_to_call_now,
    loadingTokenCompletedFirstTime,
    toggleOnTheConfirmationPopUp,
    activeProspectOnPhoneNowId,
    credits,
    finishedReason,
    predictiveDialerOn,
    prosCalledNow,
    is_continuos_calls,
  } = useSelector(dialerSelector);

  const { dialerCanCallNowInTheirWorkingHours } = useSelector(outreachCampaignSelector);

  const [seconds, setSeconds] = useState(0);
  const [addNotes, setAddNotes] = useState(false);
  const [isActiveTimer, setIsActiveTimer] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null); // TypeScript: allow null as default value

  const startTimer = () => {
    if (!isActiveTimer) {
      setAddNotes(false);
      setSeconds(0);
      setIsActiveTimer(true);
      timerRef.current = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
    }
  };

  const stopTimer = () => {
    if (isActiveTimer && timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null; // Reset to null after clearing
      setIsActiveTimer(false);
      if (seconds > 5) {
        setAddNotes(true);
        setTimeout(() => {
          setAddNotes(false);
        }, 2000);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current); // Cleanup to avoid memory leaks
      }
    };
  }, []);

  useEffect(() => {
    // console.log(dialerStatus, "dialerStatus");
    if (dialerStatus === "DISCONNECT" || dialerStatus === "OFFLINE") {
      stopTimer(); // Cleanup to avoid memory leaks
    }
    if (dialerStatus === "ON_CALL") {
      stopTimer();
      startTimer();
    }
  }, [dialerStatus]);

  const toggleMinimize = () => {
    setIsMinimized((prevState) => !prevState);
  };

  useEffect(() => {
    if (activeProspectOnPhoneNowId) {
      // startTimer();
      setOpenAiGeneratedModal(true);
      setPaused(true);
    }
  }, [activeProspectOnPhoneNowId]);

  /* not all browsers they can change the speaker and microphone
  const [toggleRecheckSpeakers, setToggleRecheckSpeakers] = useState(false);
  const [speakerDevices, setSpeakerDevices] = useState(false);
  const [micDevices, setMicDevices] = useState(false);
  const [speakerDefaultDevices, setDefaultSpeakerDevices] = useState({});
  const [micDefaultDevices, setDefaultMicDevices] = useState({});

  const [isSoundFirstTime, setIsSoundFirstTime] = useState(false);
  const [isMicFirstTime, setIsMicFirstTime] = useState(false);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const speakerDevicesFound = devices.filter((device) => device.kind === 'audiooutput');
      const micDevicesFound = devices.filter((device) => device.kind === 'audioinput');
      // remove duplicates
      const uniqueArrayMic = [];
      const uniqueGroupIdsMic = new Set();
      const uniqueArraySpeaker = [];
      const uniqueGroupIdsSpeaker = new Set();
      micDevicesFound?.forEach((mic) => {
        if (mic?.deviceId === 'default' && !isSoundFirstTime) {
          setIsSoundFirstTime(true);
          setDefaultMicDevices(mic);
          dispatch(microphoneDefaultChange(mic?.deviceId));
        }
        if (!uniqueGroupIdsMic?.has(mic?.groupId)) {
          uniqueArrayMic.push(mic);
          uniqueGroupIdsMic.add(mic?.groupId);
        }
      });
      speakerDevicesFound?.forEach((speaker) => {
        if (speaker?.deviceId === 'default' && !isMicFirstTime) {
          setIsMicFirstTime(true);
          setDefaultSpeakerDevices(speaker);
          dispatch(speakerDefaultChange(speaker?.deviceId));
        }
        if (!uniqueGroupIdsSpeaker?.has(speaker?.groupId)) {
          uniqueArraySpeaker.push(speaker);
          uniqueGroupIdsSpeaker.add(speaker?.groupId);
        }
      });
      // finish remove duplicates
      setSpeakerDevices(uniqueArraySpeaker);
      setMicDevices(uniqueArrayMic);
      console.log(speakerDevicesFound, 'Available Speaker Devices:', micDevicesFound);
    });
  }, [toggleRecheckSpeakers]);

  const handleAudioChange = (change, which) => {
    if (which === 'speaker') {
      setDefaultSpeakerDevices(change.target.value);
      dispatch(speakerDefaultChange(change.target.value?.deviceId));
    } else {
      setDefaultMicDevices(change.target.value);
      dispatch(microphoneDefaultChange(change.target.value?.deviceId));
    }
    console.log(change.target.value?.deviceId, 'User media');
  }; */

  const setOpen = (isOpen) => {
    dispatch(setPowerDialer(isOpen));
  };

  const dialerId = dialerPowerDialingOnInfo?.id;

  const [toggleLoad, setToggleLoad] = useState(true);
  const [aFewSecondsPassed, setAFewSecondsPassed] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setAFewSecondsPassed(true);
    }, 2000);
  }, []);
  const [firstCallMade, setFirstCallMade] = useState(false);

  // useEffect(() => {
  //   if () {
  //     setToggleLoad(false);
  //   }
  // }, [firstCallMade, aFewSecondsPassed, dialerStatus]);
  useEffect(() => {
    if (dialerStatus === "RECONNECT") {
      setToggleLoad(true);
    } else if (firstCallMade && aFewSecondsPassed && dialerStatus?.length) {
      setToggleLoad(false);
    }
  }, [firstCallMade, aFewSecondsPassed, dialerStatus]);

  // THIS IS THE MULTI DIALER BE CAREFUL OF CHANGING. EVERYTHING TIES IN HERE
  // const [callStatus, setCallStatus] = useState('call');
  const [isTwilioDialerSetUp, setIsTwilioDialerSetUp] = useState(false);
  const [heartbeatAct, setHeartbeatAct] = useState(false);
  const [firstCallInProgOrDid, setFirstCallInProgOrDid] = useState(false);

  // useEffect(() => {
  //   console.log(dialerStatus, "dialerStatus");
  // }, [dialerStatus]);

  useEffect(() => {
    if (dialerStatus === "ON_CALL") {
      setFirstCallMade(true);
    }
    // if (!callingInProgress && firstCallMade) {
    //   setCallStatus('nocall');
    // }
  }, [dialerStatus]);
  // const [pause, setPause] = useState(false);
  // useEffect(() => {
  //   if (pause && !activeProspectOnPhoneNowId) {
  //     setOpen(false);
  //   }
  // }, [pause]);

  // const divRef = useRef(null);

  const [multiCallHadTimeToReset, setMultiCallHadTimeToReset] = useState(true);
  useEffect(() => {
    if (multiCallingFullyPaused) {
      setMultiCallHadTimeToReset(false);
      setTimeout(() => {
        setMultiCallHadTimeToReset(true);
      }, 6000);
    }
  }, [multiCallingFullyPaused]);

  // useEffect(() => {
  //   if (divRef.current && firstCallInProgOrDid) {
  //     setPause(true);
  //   } else {
  //     setPause(false);
  //   }
  // }, [divRef.current]);

  const [phone, setPhone] = useState("");
  const [isThereMoreLeadsInFeToLoadIn, setIsMoreLeads] = useState(false);
  const [openUpg, setOpenUpg] = useState(false);
  const [upgPopUpOpenedOnce, setUpgPopUpOpenedOnce] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [wantsClose, setWantsClose] = useState(false);

  const confirmWantsClose = () => {
    // dispatch(stopMultiDialingActive());
    // setWantsClose(true);
  };
  useEffect(() => {
    if (credits !== null && credits <= 0 && !upgPopUpOpenedOnce) {
      setUpgPopUpOpenedOnce(true);
      setOpenUpg(true);
    }
  }, [credits]);

  // useEffect(() => {
  //   if (finishedWithProspectsInDialer) {
  //     setToggleLoad(false);
  //   }
  // }, [finishedWithProspectsInDialer]);
  // useEffect(() => {
  //   if (wantsClose && multiCallingFullyPaused && !activeProspectOnPhoneNowId) {
  //     setOpen(false);
  //     setConfirmationOpen(false);
  //   }
  // }, [wantsClose, multiCallingFullyPaused, activeProspectOnPhoneNowId]);

  useEffect(() => {
    let timer;
    let timer2;
    if (popupMessage === "Reconnecting To Virtual Line") {
      timer = setTimeout(() => {
        setPopupMessage("Successful! Back To Dialing More Now.");
      }, 3000);
    } else if (popupMessage === "Successful! Back To Dialing More Now.") {
      timer2 = setTimeout(() => {
        setPopupMessage("");
      }, 3000);
    }

    return () => {
      clearTimeout(timer2);
      clearTimeout(timer);
    };
  }, [popupMessage]);

  useEffect(() => {
    // this is if they click start power dialer while its already open
    if (aFewSecondsPassed) {
      setConfirmationOpen(true);
    }
  }, [toggleOnTheConfirmationPopUp]);

  // const [activeProspectOnPhoneNowIdNotification, setActiveProspectOnPhoneNowIdNotification] = useState(null);
  const [onCallWithPros, setOnCallWithPros] = useState(false);
  const [checkedId, setCheckedId] = useState();
  useEffect(() => {
    // this is if they click start power dialer while its already open
    if (activeProspectOnPhoneNowId && activeProspectOnPhoneNowId !== checkedId) {
      // console.log('ssiue!1');
      setOnCallWithPros(true);
      setCheckedId(activeProspectOnPhoneNowId);
      // dispatch(whoIsActiveContact());
      // dispatch(whoIsActiveContactLinkedIn());
      // dispatch(whoIsActiveContactCompany());
    }
  }, [activeProspectOnPhoneNowId]);

  const hangupAfterAnswer = () => {
    // dispatch(

    dispatch(readyToDialSwitch(false));
    // console.log("ssiue!2", deviceUsing);
    dispatch(stopMultiDialingActive({}));
    if (deviceUsing) {
      deviceUsing.disconnectAll();
      deviceUsing.destroy();
      setDeviceUsing(null);
      dispatch(resetVoiceToken());
    }
    // setOnCallWithPros(false);
    // setOpen(false);
  };
  const hangup = () => {
    // dispatch(

    dispatch(readyToDialSwitch(false));
    // console.log("ssiue!2", deviceUsing);
    dispatch(stopMultiDialingActive({}));
    if (deviceUsing) {
      deviceUsing.disconnectAll();
      deviceUsing.destroy();
      setDeviceUsing(null);
      dispatch(resetVoiceToken());
    }
    setOnCallWithPros(false);
    setOpen(false);
  };

  const closeTheProsWindow = () => {
    setSeconds(0);
    hangupAfterAnswer(); // maybe keep open
    setOpenAiGeneratedModal(false);

    dispatch(resetMultiDialerPros());
    // setTimeout(() => {
    if (is_continuos_calls) {
      startCallFromPopup();
    }
    // }, 1000);
  };

  useEffect(() => {
    if (finishedWithProspectsInDialer) {
      hangup();
    }
  }, [finishedWithProspectsInDialer]);

  // useEffect(() => {
  //   console.log(deviceUsing, "deviceUsinglogs");
  // }, [deviceUsing]);

  // const [topNotification, settTopNotification] = useState();
  const [noBalanceLeft, setNoBalance] = useState(false);

  useEffect(() => {
    if (finishedReason) {
      let notifciation;
      switch (finishedReason) {
        case "no_prospects_left":
          setIsCompleted(true);
          notifciation = `No prospects left to call at this time`;
          dispatch(setDialerCanCallNowInTheirWorkingHours(0));
          // hangup();
          break;
        case "no_prospects_to_begin_with":
          setIsCompleted(true);
          notifciation = `No prospects to call at this time`;
          dispatch(setDialerCanCallNowInTheirWorkingHours(0));
          // hangup();
          break;
        case "no_money_left":
          setNoBalance(true);
          notifciation = `No dialer balance left`;
          break;
        case "something_went_wrong":
          setIsCompleted(true);
          notifciation = `Something went wrong, please try again later`;
          // hangup();
          break;
      }
      dispatch(setFinishedReason(null));
      message.info(notifciation);
      hangupAfterAnswer();
      // setTimeout(() => {
      // setIsCompleted(false);
      // setNoBalance(false);
      // }, 6000);
    }
  }, [finishedReason]);

  // console.log(predictiveDialerOn, isCompleted, noBalanceLeft, "whystillopen");

  const [openManageBillMod, setOpenManageBillMod] = useState(false);

  const openModBil = () => {
    setOpenManageBillMod(true);
  };
  const closeModBil = () => {
    setOpenManageBillMod(false);
  };

  const onClosePopUp = () => {
    hangup();
    setIsCompleted(false);
    setNoBalance(false);
    setPaused(false);
  };

  const pauseDialing = () => {
    dispatch(setStopDialing(true));
    setPaused(true);
    hangup();
  };
  const [phoneNumberToCallInfo, setPhoneNumberToCallInfo] = useState(null);

  const startCallFromPopup = () => {
    setPhoneNumberToCallInfo(null);
    setDialerStatus("");
    dispatch(setPowerDialer(true));
    setIsTwilioDialerSetUp(false);
    setFirstCallInProgOrDid(false);
    setIsCompleted(false);
    setPaused(false);
    dispatch(setStopDialing(false));
    setDialerStatus("call");
    // dispatch(resetMultiDialerPros(true)); // not good with linkedin
  };

  const startCallFromPopupDontResetPros = () => {
    setDialerStatus("");
    dispatch(setPowerDialer(true));
    setIsTwilioDialerSetUp(false);
    setFirstCallInProgOrDid(false);
    setIsCompleted(false);
    setPaused(false);
    dispatch(setStopDialing(false));
    setDialerStatus("call");
  };

  const popUpOpen = !!predictiveDialerOn || !!isCompleted || !!noBalanceLeft || !!paused;

  // console.log(popUpOpen, "popUpOpenpopUpOpen", !!predictiveDialerOn, !!isCompleted, !!noBalanceLeft, !!paused);

  useEffect(() => {
    if (!popUpOpen) {
      setDialerStatus("");
      setIsTwilioDialerSetUp(false);
      setFirstCallInProgOrDid(false);
      dispatch(setIsPopUpOpen(false));
      setPhoneNumberToCallInfo(null);
    } else {
      dispatch(setIsPopUpOpen(true));
    }
  }, [popUpOpen]);

  // console.log(popUpOpen, "Will go in popUpOpen");

  const callPhoneFnction = (num) => {
    // console.log(num?.phone, "numnum");
    if (!dialerStatus || dialerStatus === "DISCONNECT" || dialerStatus === "OFFLINE") {
      setPhoneNumberToCallInfo({ phone: num?.phone, prosId: activeProspectOnPhoneNowId });
      startCallFromPopupDontResetPros();
    }
  };
  // console.log(dialerCanCallNowInTheirWorkingHours, "dialerCanCallNowInTheirWorkingHours111");
  const handleIncomingMessageFromServer = (message) => {
    // console.log(dialerCanCallNowInTheirWorkingHours, "dialerCanCallNowInTheirWorkingHours222");
    const { type, data } = message;
    // console.log(message, "handleIncomingMessageFromServer");
    switch (type) {
      case "salesProsOnCallWith":
        dispatch(activelyOnCall(data));
        break;
      case "salesProsOnCallWithLinkedin":
        // console.log("hello1213D", data);
        // console.log(data, "salesProsOnCallWithLinkedin");
        // dispatch(activelyOnCall(data));
        dispatch(setWhoIsActLinkedInOnCall(data));
        break;
      case "salesProsOnCallWithCompany":
        // console.log(data, "salesProsOnCallWithCompany");
        dispatch(setWhoIsActCompanyOnCall(data));
        // dispatch(activelyOnCall(data));
        break;
      case "stoppedDialing":
        // console.log(data, "salesProsOnCallWithCompany");
        // const reason = data?.reason;
        dispatch(setFinishedReason(data?.reason));
        // dispatch(activelyOnCall(data));
        break;
      case "amountOfProsCalling":
        // const prospectsCanCallNowAmnt = data?.prospectsCanCallNowAmnt
        // const amountCalledNow = data?.amountCalledNow
        // console.log(data, "amountOfProsCalling");
        console.log(data, "amountOfProsCalling");
        dispatch(setProsCalled(data));
        // REAL TIME DATA DOESNT GET IN HERE!!!
        // const updCanCallNow = dialerCanCallNowInTheirWorkingHours - +(data?.amountCalledNow || 0);
        // // console.log(data?.amountCalledNow, updCanCallNow, dialerCanCallNowInTheirWorkingHours, "amountOfProsCallingCheck");
        // const canCallNow = updCanCallNow < 0 ? 0 : updCanCallNow;
        // dispatch(setDialerCanCallNowInTheirWorkingHours(canCallNow));
        // const updCanCallNow = dialerCanCallNowInTheirWorkingHours - +(data?.amountCalledNow || 0);
        // console.log(data?.amountCalledNow, updCanCallNow, dialerCanCallNowInTheirWorkingHours, "amountOfProsCallingCheck");
        // const canCallNow = updCanCallNow < 0 ? 0 : updCanCallNow;
        // dispatch(setDialerCanCallNowInTheirWorkingHours(canCallNow));
        dispatch(setDialerCanCallNowInTheirWorkingHoursReduceBy(data));

        dispatch(setDialerCanCallNowInTheirWorkingHours(data?.prospectsCanCallNowAmnt));
        break;
    }
  };
  // console.log(prosCalledNow, "prosCalledNow?.prospectsCanCallNowAmnt");

  const { id } = useSelector(userSelector);
  // console.log(id, "idid");
  useEffect(() => {
    let socket;
    if (id) {
      socket = io(`${API_BASE}/dialerwebsocket?clientId=${id}`); // Replace with your NestJS server URL

      // Listen for messages from the server
      socket?.on("messageFromServer", (message) => {
        // console.log("Message from server:", message);
        handleIncomingMessageFromServer(message);
      });

      // Cleanup on component unmount
    } else {
      socket?.disconnect();
    }
    return () => {
      // console.log("hello1213DISCONNECT");
      socket?.disconnect();
    };
  }, [id]);

  console.log(
    !isCompleted,
    !noBalanceLeft,
    !paused,
    openAiGeneratedModal,
    !phoneNumberToCallInfo,
    "checkkkk",
    !isCompleted && !noBalanceLeft && (openAiGeneratedModal || !paused) && !phoneNumberToCallInfo
  );

  return (
    <>
      {popUpOpen && (
        <>
          {!!phoneNumberToCallInfo && (
            <TwilioComponent
              key={1}
              // callStatus={callStatus}
              phoneNumberToCallInfo={phoneNumberToCallInfo}
              dialerStatus={dialerStatus}
              setDialerStatus={setDialerStatus}
              heartbeatAct={heartbeatAct}
              setHeartbeatAct={setHeartbeatAct}
              setPopupMessage={setPopupMessage}
              setMessage={setMessageToShow}
              // resetNowTwilio={resetNowTwilio}
              // setResetNowTwilio={setResetNowTwilio}
              firstCallInProgOrDid={firstCallInProgOrDid} // leave
              setFirstCallInProgOrDid={setFirstCallInProgOrDid} // leave
              isTwilioDialerSetUp={isTwilioDialerSetUp}
              setIsTwilioDialerSetUp={setIsTwilioDialerSetUp}
              deviceUsing={deviceUsing}
              setDeviceUsing={setDeviceUsing}
            />
          )}
          {!isCompleted && !noBalanceLeft && (openAiGeneratedModal || !paused) && !phoneNumberToCallInfo && (
            <TwilioComponent
              key={2}
              // callStatus={callStatus}
              // phoneNumberToCallInfo={phone}
              dialerStatus={dialerStatus}
              setDialerStatus={setDialerStatus}
              multi
              heartbeatAct={heartbeatAct}
              setHeartbeatAct={setHeartbeatAct}
              setPopupMessage={setPopupMessage}
              setMessage={setMessageToShow}
              // resetNowTwilio={resetNowTwilio}
              // setResetNowTwilio={setResetNowTwilio}
              firstCallInProgOrDid={firstCallInProgOrDid} // leave
              setFirstCallInProgOrDid={setFirstCallInProgOrDid} // leave
              isTwilioDialerSetUp={isTwilioDialerSetUp}
              setIsTwilioDialerSetUp={setIsTwilioDialerSetUp}
              deviceUsing={deviceUsing}
              setDeviceUsing={setDeviceUsing}
            />
          )}
          <Flex className={"parallel-dialer-wrapper"} vertical style={{ height: isCompleted ? 200 : 264 }}>
            <Row justify={"space-between"} style={{ paddingBottom: 28 }}>
              <Typography.Title level={3}>Phone Dialer</Typography.Title>
              <SvgIcon type={"close"} style={{ cursor: "pointer" }} onClick={onClosePopUp} />
            </Row>
            {(!dialerStatus || dialerStatus === "READY") && !paused && !isCompleted && !noBalanceLeft && !prosCalledNow?.prospectsCanCallNowAmnt && (
              <Loader tip={"Getting Ready"} style={{ padding: "41px 0" }} />
            )}
            {
              !!prosCalledNow?.prospectsCanCallNowAmnt && !paused && !isCompleted && !noBalanceLeft && (
                <Flex justify={"center"} align={"center"} style={{ marginTop: 15, flexDirection: "column", marginBottom: "-55px" }}>
                  <SvgIcon type={"phoneCall"} style={{ color: "#01AEEF", height: "25px", width: "25px" }} />
                  <Typography.Paragraph style={{ color: "#01AEEF", fontSize: "16px" }}>Dialing now</Typography.Paragraph>
                </Flex>
              )
              // <Loader tip={"Dialing"} style={{ padding: "41px 0" }} />
            }
            {/* phoneCall */}
            {paused && <DialerPopupInfo iconType={"pauseOutline"} bottomText={"Paused"} />}
            {noBalanceLeft && <DialerPopupInfo iconType={"battery"} bottomText={"No dialer balance left"} />}
            {/* {topNotification && <DialerPopupInfo iconType={"checked"} bottomText={"Completed fix the above"} />} */}
            {isCompleted && !paused && <DialerPopupInfo iconType={"checked"} bottomText={"Completed"} />}
            {/* {isCompleted && <DialerPopupInfo iconType={"checked"} bottomText={"Completed"} />} */}
            {/* {!!prosCalledNow?.prospectsCanCallNowAmnt && !isCompleted && ( */}
            {!isCompleted && (
              <Row justify={"space-between"} align={"middle"} wrap={false} style={{ paddingTop: paused || noBalanceLeft || isCompleted ? 40 : 110 }}>
                {!!prosCalledNow?.prospectsCanCallNowAmnt ? (
                  <Space direction={"vertical"} size={4}>
                    <Typography.Paragraph>{`Called: ${prosCalledNow?.amountCalledNow}`}</Typography.Paragraph>
                    <Typography.Paragraph>{`Out of: ${prosCalledNow?.prospectsCanCallNowAmnt}`}</Typography.Paragraph>
                  </Space>
                ) : (
                  <Space direction={"vertical"} size={4}>
                    {" "}
                  </Space>
                )}
                {!isCompleted && (
                  <Button className={"primary"} onClick={paused ? startCallFromPopup : !noBalanceLeft ? pauseDialing : openModBil}>
                    {paused ? "Resume Dialing" : noBalanceLeft ? "Buy minutes" : "Pause"}
                  </Button>
                )}
              </Row>
            )}
            {isReconnecting && (
              <Typography.Paragraph className={"complete-comment"}>
                *The system will automatically connect to the dialing environment after 10 minutes.
              </Typography.Paragraph>
            )}
          </Flex>{" "}
          {/* <AiGeneratedPersonalIntroModal open={openAiGeneratedModal} onCancel={closeTheProsWindow} data={aiGeneratedPersonalData} /> */}
          <AiGeneratedPersonalIntroModal
            open={openAiGeneratedModal}
            onCancel={closeTheProsWindow}
            data={aiGeneratedPersonalData}
            onHangUp={hangupAfterAnswer}
            callPhoneFnction={callPhoneFnction}
            callSeconds={seconds}
            onHangUpAndClose={closeTheProsWindow}
            addNotes={addNotes}
          />
        </>
      )}
      <ManageCallBalanceModal open={openManageBillMod} close={closeModBil} fromDialerPopup />
    </>
  );
};
