import { Button, Flex, Form, Input, message, Radio, Tooltip, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageLayout from "src/components/layout/PageLayout";
import { ManageEmailDrawer } from "src/components/pages/emails-data";
import { statisticEmailsData } from "src/components/pages/emails-data/info";
import { EmailsTable } from "src/components/pages/emails-data/ui/EmailsTable";
import { StatisticsBar } from "src/components/ui/StatisticsBar";
import { SvgIcon } from "src/shared/icons";
import { useBreakpoints } from "../../helpers/useBreakpoints";
import { GoToDesktopModal } from "../../components/ui/GoToDesktopModal";
import { EmptyState } from "../../components/ui/EmptyState";
import { useDispatch, useSelector } from "react-redux";
import { outreachEmailsSelector, resetOutcome } from "src/store/outreach-emails";
import { addEmailBe, getCampAnalytics } from "src/store/outreach-emails/asyncRequests";
import { useNavigate } from "react-router";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import { ConfirmButtons, Loader, Modal } from "src/components/ui";
import "./index.less";

interface EmailsProps {
  isTourStep?: boolean;
}

const Emails: FC<EmailsProps> = ({ isTourStep }) => {
  const { isDesktop } = useBreakpoints();
  const [openDrawer, setOpenDrawer] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onCancel = () => {
    setOpenDrawer(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => typeof prop === "string" && searchParams.get(prop),
    });
    const err = params?.error;
    const email = params?.em;
    const id = params?.id;
    const type = params?.type;
    const iframe = params?.iframe;
    // console.log(params, "params");
    const savedPathSegment = localStorage.getItem("latestMeetzPg");
    // console.log(savedPathSegment, "savedPathSegment");
    if (savedPathSegment && savedPathSegment !== "email-analytics") {
      // localStorage.removeItem('latestMeetzPg');

      const searchParams = new URLSearchParams(window.location.search);

      const newUrl = `${savedPathSegment}?${searchParams.toString()}`;

      // Construct the final URL, excluding the 'email-analytics' part if it's part of the current path
      const pathParts = location.pathname.split("/").filter((part) => part !== "email-analytics");
      const basePath = pathParts.join("/");

      // If basePath is empty, ensure the URL starts with a '/'
      const finalUrl = `${basePath}/${newUrl}`.replace(/\/\//g, "/");
      // console.log(finalUrl, "basePath", savedPathSegment, searchParams.toString());
      // Use window.history.pushState to update the URL without reloading the page
      // window.history.pushState({}, '', finalUrl);
      // console.log(finalUrl, "finalUrl");
      navigate(finalUrl);
      return;
    }
    if (err) {
      // push('/ai_email_campaign');
      // dispatch(emailErr({ email, type }));
      message.destroy();
      message.error(
        `Error integrating your ${
          type === "google" ? "Google" : "Outlook"
        } email. Make sure that you accepted the necessary permissions. Try to connect to it again soon.`
      );
    } else if (email && id) {
      dispatch(addEmailBe({ id, email }));
      message.destroy();
      message.info("Adding your email address now...");

      if (iframe === "true") {
        const jwt = localStorage.getItem("token");
        if (jwt) {
          window.location.href = `https://marketing.deal.ai/apps/app/meetz?appTokenName=token&appTokenValue=${jwt}`;
        } else {
          window.location.href = `https://marketing.deal.ai/apps/app/meetz`;
        }
      }
      // if(local){

      // }
      window.history.pushState({}, "", "/email-analytics");
      setOpenDrawer(true);
    }
  }, []);
  const { successfullUpdateMsg, failedUpdateMsg, infoUpdateMsg, campaignDisplayingAnalyticsId, dataAll } = useSelector(outreachEmailsSelector);

  const [filterBox, setFilterBox] = useState(false);
  const { allCampsList } = useSelector(outreachCampaignSelector);
  const [campaignDisplayingName, setCampaignDisplayingName] = useState<string>("Loading...");

  const [campToCopId, setCampToCopId] = useState<string | undefined>("");
  const campIdSeeing = dataAll?.campaignId;
  useEffect(() => {
    // console.log(campIdSeeing, "campIdSeeing");
    setCampToCopId(campIdSeeing);
    form.setFieldsValue({ camptocopy: campIdSeeing });
    if (campIdSeeing) {
      if (campIdSeeing === "all") {
        setCampaignDisplayingName("All Campaigns");
      } else {
        const findCamp = allCampsList?.find((item) => item?.id === campIdSeeing);
        setCampaignDisplayingName(findCamp?.campName);
      }
    } else {
      setCampaignDisplayingName("Loading...");
    }
  }, [campIdSeeing]);

  const rightHeaderContent = (
    <Flex style={{ width: "72%" }} justify="center" align="center">
      {" "}
      {allCampsList?.length > 1 ? (
        <>
          <div className="filtered-box" onClick={() => dataAll?.campaignId && setFilterBox(true)}>
            <div className="filtered-container">
              <span className="filtered-campaign" style={{ marginLeft: "auto", marginRight: "0px" }}>
                {" "}
                Displaying Analytics:{" "}
              </span>
              <span className="named-filtered-camp">
                {campaignDisplayingName?.length > 30 ? (
                  <Tooltip placement={"top"} title={campaignDisplayingName}>
                    {" "}
                    {campaignDisplayingName?.substring(0, 30) + "..."}
                  </Tooltip>
                ) : (
                  campaignDisplayingName
                )}
              </span>
            </div>
          </div>
          {/* <Button className={"link"} onClick={() => dataAll?.campaignId && setFilterBox(true)} style={{ marginLeft: "auto", marginRight: "35px" }}>
            {!dataAll?.campaignId && <Loader size={"small"} style={{ marginRight: "40px" }} />} <div>Filter Analytics By Campaign</div>
          </Button> */}
        </>
      ) : (
        ""
      )}
      <Button className={"primary"} onClick={showDrawer} style={{ marginLeft: allCampsList?.length > 1 ? 0 : "auto" }}>
        Setup Your Email Addresses
      </Button>
    </Flex>
  );

  const [activeId, setActiveId] = useState<string | null>("email_sent");

  const dispatch = useDispatch();

  useEffect(() => {
    if (successfullUpdateMsg) {
      message.destroy();
      message.success(successfullUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [successfullUpdateMsg]);

  useEffect(() => {
    if (failedUpdateMsg) {
      message.destroy();
      message.error(failedUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [failedUpdateMsg]);

  useEffect(() => {
    if (infoUpdateMsg) {
      message.destroy();
      message.info(infoUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [infoUpdateMsg]);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [form] = Form.useForm();
  const handleRadioBtnChange = (e: any) => {
    setCampToCopId(e.target.value);
    form.setFieldsValue({ camptocopy: e?.target?.value }); // Ensure form updates with selected campaign
  };
  const filteredCampaigns = allCampsList?.filter((item) => item?.campName?.toLowerCase()?.includes(searchTerm?.toLowerCase()))?.slice(0, 5);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const closeModal = () => {
    setFilterBox(false);
    // setTimeout(() => {
    //   setIsSubmitting(false);
    // }, 1000);
  };
  const onFinish = (vals) => {
    // if (isSubmitting) return;
    // setIsSubmitting(true);
    // console.log(vals, "VALSVALS");

    dispatch(getCampAnalytics({ campIdToShow: vals?.camptocopy }));
    setActiveId("email_sent");
    closeModal();
  };
  // console.log(campToCopId, "campToCopId");
  return isDesktop ? (
    <>
      <Modal
        open={filterBox}
        title={"Filter analytics by campaign"}
        onCancel={closeModal}
        styles={{ header: { maxWidth: "400px" }, body: { minHeight: "380px" } }}
      >
        <Form form={form} layout={"vertical"} className={"form"} onFinish={onFinish}>
          <Flex vertical gap={15}>
            <Typography.Paragraph>Displaying: {campaignDisplayingName}</Typography.Paragraph>
            {/* <Flex vertical gap={10}>
              <Typography.Paragraph>New campaign's name</Typography.Paragraph>
              <Form.Item
                name={"name"}
                // label={"New campaign's name"}
                rules={[{ required: true, message: "Please enter a campaign name" }]}
                style={{ width: "350px" }}
              >
                <Input placeholder={"Enter name"} />
              </Form.Item>
            </Flex> */}
            <Flex vertical gap={10}>
              <Typography.Paragraph>Search for campaign</Typography.Paragraph>
              <Input.Search
                className={"search-input"}
                placeholder={"Search campaigns name..."}
                allowClear
                prefix={<SvgIcon type={"search"} />}
                enterButton={false}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Form.Item name={"camptocopy"} rules={[{ required: true, message: "Select campaign to copy" }]} style={{ width: "350px" }}>
                <Radio.Group defaultValue={campToCopId} value={campToCopId} onChange={handleRadioBtnChange} style={{ padding: "10px" }}>
                  <Flex gap={10} vertical>
                    <Radio key={`all`} value={"all"}>
                      <b>All Campaigns</b>
                    </Radio>
                    {filteredCampaigns?.length
                      ? filteredCampaigns.map((item, index) => (
                          <Radio key={`${index}-${item.id}`} value={item.id}>
                            {item.campName}
                          </Radio>
                        ))
                      : // <div>No campaign found</div>
                        ""}
                  </Flex>
                </Radio.Group>
              </Form.Item>
            </Flex>
            <div style={{ position: "absolute", width: "85%", left: "auto", right: "auto", bottom: 20 }}>
              <ConfirmButtons
                handleSubmit={() => form.submit()}
                onCancel={closeModal}
                rightButtonTitle={"Display Analytics"}
                leftButtonTitle={"Back"}
                disabled={!allCampsList?.length}
                disabledExplanation={`No campaigns found to copy`}
              />
            </div>
          </Flex>
        </Form>
      </Modal>
      <PageLayout
        pageTitle={"Emails"}
        bottomHeaderContent={<StatisticsBar campaignId={"all"} dataType={"email"} activeId={activeId} setActiveId={setActiveId} />}
        rightHeaderContent={rightHeaderContent}
      >
        {/* {statisticEmailsData.length === 0 ? (
        <EmptyState text={"You have no emails sent. Start your campaign, and the email analytics will appear here."} />
      ) : ( */}
        <EmailsTable isTourStep={isTourStep} campaignId={"all"} activeId={activeId} />
        {/* )} */}
        {/* do not make manageemaildrawer not preeent in react othersise will ruin the logic for email. or just move that logci to this page */}
        <ManageEmailDrawer open={openDrawer} onCancel={onCancel} />
      </PageLayout>
    </>
  ) : (
    <GoToDesktopModal />
  );
};

export default Emails;
