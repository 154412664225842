/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import {
  resetDialerStatus,
  dialerSelector,
  hangUpOnMultiProspectChange,
  numberToCallInMulti,
  multiCallBackRingingStatus,
  setStopDialing,
  resetActiveCallId,
  activelyOnCall,
  setWhoIsActCompanyOnCall,
  setWhoIsActLinkedInOnCall,
  numberToDial,
  setFinishedReason,
  setProsCalled,
} from "src/store/dialer";
import axios from "axios";
import { getTwilioVoiceToken, heartbeatToShowOnlineAndGetStatus } from "src/store/dialer/asyncRequests";
import { Device } from "twilio-client";
import audio from "src/shared/sound/startCall.mp3";
import { outreachPhonesSelector } from "src/store/outreach-phones";
import { API_BASE } from "src/pages/constant";
import { updateCampaignsDialers } from "src/store/outreach-phones/asyncRequests";
import { userSelector } from "src/store/user";
// import {  } from 'store/dialer';

export const TwilioComponent = ({
  // popUpDialStatus,
  // setPopUpDialStatus,
  phoneNumberToCallInfo,
  dialerStatus,
  setDialerStatus,
  multi,
  heartbeatAct,
  setHeartbeatAct,
  setMessage,
  firstCallInProgOrDid,
  isTwilioDialerSetUp,
  setIsTwilioDialerSetUp,
  setFirstCallInProgOrDid,
  deviceUsing,
  setDeviceUsing,
  // popUpDialStatus,
  // setPopUpDialStatus,
  // resetNowTwilio,
  // setResetNowTwilio,
  selectedPhoneCallFromSingleMode,
}) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState([]);
  const {
    //   twilioTokens,
    // dialerInfo,
    stopDialing,
    voiceToken: token,
    //   prospectAnsweredNowSid,
    dialerPowerDialingOnInfo,
    //   hangUpOnMultiProspect,
    //   multiCallingFullyPaused,
    //   finishedWithProspectsInDialer,
    //   speakerDefault,
    //   microphoneDefault,
    activeProspectOnPhoneNowId,
    numberToDialOnCallNow,
    //   callIsLiveNow,
    //   callInMultiNum,
    //   callInMultiNumExt,
    //   callingInProgress,

    // hangup,
  } = useSelector(dialerSelector);
  // const dialerr = useSelector(dialerSelector);
  console.log("dialerSelector", dialerPowerDialingOnInfo);
  const { phone_numbers } = useSelector(outreachPhonesSelector);

  let phone_number_using_now = dialerPowerDialingOnInfo?.overview?.phone_number_using_now;
  if (!phone_number_using_now && dialerPowerDialingOnInfo?.overview?.campId) {
    phone_number_using_now = phone_numbers?.find((num) => num.is_active);

    // axios.post(`${API_BASE}/aiSales/updateCampaignsDialers`);
    dispatch(updateCampaignsDialers());
  }

  const phone_num = phone_numbers?.find((num) => num.phone_number === phone_number_using_now);
  const phone_num_of_cli = selectedPhoneCallFromSingleMode || phone_num?.phone_number;
  console.log(phone_num_of_cli, "phone_num", dialerPowerDialingOnInfo, phone_numbers);
  const [conn, setConn] = useState(false);
  const [toggleDoAnotherConnection, setToggleDoAnotherConnection] = useState(false);
  // const accountSid = dialerInfo?.twilio_subaccount_sid;
  // const authToken = dialerInfo?.twilio_auth_token;

  // general twilio front end
  useEffect(() => {
    console.log(deviceUsing, "device using changes");
    if (deviceUsing) {
      deviceUsing.on("connect", (connection) => {
        setConn(true);
        setDialerStatus("ON_CALL");
      });
      deviceUsing.on("disconnect", () => {
        deviceUsing.disconnectAll();
        setDialerStatus("DISCONNECT");
        setConn(false);
        if (dialerStatus) {
          dispatch(multiCallBackRingingStatus({ dialerStatus: "" }));
        }
      });
      deviceUsing.on("incoming", (connection) => {
        setDialerStatus("INCOMING");
        setConn(connection);
        connection.accept();
        connection.on("reject", () => {
          setDialerStatus("READY");
          setConn(false);
        });
        if (dialerStatus) {
          dispatch(multiCallBackRingingStatus({ dialerStatus: "" }));
        }
      });
      deviceUsing.on("cancel", () => {
        setDialerStatus("CANCEL");
        setConn(false);
        if (dialerStatus) {
          dispatch(multiCallBackRingingStatus({ dialerStatus: "" }));
        }
      });
      deviceUsing.on("reject", () => {
        setDialerStatus("REJECT");
        setConn(false);
        if (dialerStatus) {
          dispatch(multiCallBackRingingStatus({ dialerStatus: "" }));
        }
      });
      deviceUsing.on("error", (error) => {
        console.log("Dialer Status: ERROR device using changes", error);
        setDialerStatus("ERROR");
      });
      deviceUsing.on("offline", () => {
        console.log("Dialer Status: offline device using changes");
        setDialerStatus("OFFLINE");
      });
      deviceUsing.on("registered", () => {
        setDialerStatus("REGISTERED");
      });
      deviceUsing.on("unregistered", () => {
        setDialerStatus("UNREGISTERED");
      });
    }
  }, [deviceUsing]);

  useEffect(() => {
    console.log(dialerStatus, "device using changesdialerStatus");
  }, [dialerStatus]);

  // multi mode
  useEffect(() => {
    if (multi) {
      console.log(
        !!token,
        dialerStatus,
        isTwilioDialerSetUp,
        firstCallInProgOrDid,
        "Will go in",
        !!(
          (dialerStatus !== "READY" &&
            (!isTwilioDialerSetUp ||
              // after 10 min when hanged up and need to reconnect to the queue
              // dialerStatus === 'DISCONNECT')) ||
              (firstCallInProgOrDid && dialerStatus === "DISCONNECT"))) ||
          dialerStatus === "RECONNECT"
        )
        // !!token
      );
      if (
        (dialerStatus !== "READY" &&
          (!isTwilioDialerSetUp ||
            // after 10 min when hanged up and need to reconnect to the queue
            // dialerStatus === 'DISCONNECT')) ||
            (firstCallInProgOrDid && dialerStatus === "DISCONNECT"))) ||
        dialerStatus === "RECONNECT"
      ) {
        if (token && !stopDialing) {
          if (dialerStatus === "RECONNECT" || dialerStatus === "DISCONNECT") {
            setMessage("Reconnecting To Virtual Line");
            setFirstCallInProgOrDid(false);
          }
          console.log("connnectwil");
          // setResetNowTwilio(false);
          setIsTwilioDialerSetUp(true);
          const device = new Device();

          device.setup(token, { debug: true, ringtone: true });
          // device.setup(token, { debug: true });
          setDeviceUsing(device);
          device.on("ready", () => {
            console.log("ready???device using changes");
            setDialerStatus("READY");
            if (multi && firstCallInProgOrDid) {
              setToggleDoAnotherConnection(true);
            }
          });
          console.log("device using changes 123");
          return () => {
            if (deviceUsing) {
              // deviceUsing.disconnectAll();
            }

            // setDialerStatus('OFFLINE');
          };
        } else if (!token) {
          dispatch(getTwilioVoiceToken());
        }
      }
    }
  }, [token, dialerStatus, isTwilioDialerSetUp, firstCallInProgOrDid, stopDialing]);

  // useEffect(() => {
  //   if (stopDialing) {
  //     dispatch(resetActiveCallId());
  //   }
  // }, [stopDialing]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (heartbeatAct) {
  //       dispatch(heartbeatToShowOnlineAndGetStatus({ cli_phone_number: phone_num_of_cli, stopDialing }));
  //     }
  //   }, 1000);
  //   // Check if the state variable has changed
  //   if (!heartbeatAct) {
  //     clearInterval(interval);
  //   }

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [heartbeatAct]);

  // console.log(numberToDialOnCallNow, "numberToDialOnCallNow!");
  useEffect(() => {
    console.log(numberToDialOnCallNow, "numberToDialOnCall11", !!deviceUsing);
    if (deviceUsing && numberToDialOnCallNow) {
      deviceUsing?.activeConnection()?.sendDigits(numberToDialOnCallNow);
      dispatch(numberToDial(null));
    }
  }, [numberToDialOnCallNow]);
  // const [fiveSecondsPassed, setFiveSecondsPassed] = useState(true);
  // useEffect(() => {
  //   if (!fiveSecondsPassed) {
  //     const timeout = setTimeout(() => {
  //       setFiveSecondsPassed(true);
  //     }, 7000);

  //     return () => {
  //       clearTimeout(timeout);
  //     };
  //   }
  // }, [fiveSecondsPassed]);
  useEffect(() => {
    // console.log(
    //   !!token,
    //   phone_num_of_cli,
    //   deviceUsing,
    //   dialerStatus,
    //   multi,
    //   firstCallInProgOrDid,
    //   dialerPowerDialingOnInfo?.overview?.campId,
    //   "will go in 2",
    //   !!token && phone_num_of_cli && deviceUsing && dialerStatus === "READY" && multi && !firstCallInProgOrDid && dialerPowerDialingOnInfo?.overview?.campId
    // );

    if (
      token &&
      phone_num_of_cli &&
      deviceUsing &&
      dialerStatus === "READY" &&
      multi &&
      !firstCallInProgOrDid &&
      dialerPowerDialingOnInfo?.overview?.campId &&
      !stopDialing
      // !activeProspectOnPhoneNowId
    ) {
      // dispatch(resetDialerStatus()); I shut this off buy maybe not to?
      setFirstCallInProgOrDid(true);
      setHeartbeatAct(true);

      // startHeartBeat();
      if (deviceUsing.activeConnection()) {
        // Disconnect the active connection before making a new one
        // deviceUsing.disconnectAll();
      }

      const connection = deviceUsing.connect({ Caller: dialerPowerDialingOnInfo?.overview?.campId, To: `+19998887777` });
      new Audio(audio).play();
      // connection.on("accept", () => {
      //   console.log("Call connected!1111111111");
      // });

      // connection.on("disconnect", () => {
      //   console.log("Call disconnected.1111111111");
      // });
      // connection.on("ringing", () => {
      //   console.log("Ringing... 1111111111");
      // });
      // connection.on("connecting", () => {
      //   console.log("connecting 1111111111...");
      // });

      // connection.on("error", (error) => {
      //   console.error("Connection error:1111111111", error);
      // });
      // console.log("device using changes 111", connection);
      // setFiveSecondsPassed(false); might need this to not update (If hang up fast hearbeat could send id quickly and it;ll look like a pop up)
    }
  }, [token, phone_num_of_cli, deviceUsing, dialerStatus, multi, firstCallInProgOrDid, dialerPowerDialingOnInfo, stopDialing]);

  const [activeProspectOnPhoneNowIdNotification, setActiveProspectOnPhoneNowIdNotification] = useState(null);
  const [tenSecondsPassed, setTenSecondsPassed] = useState(true);
  useEffect(() => {
    if (!tenSecondsPassed) {
      const timeout = setTimeout(() => {
        setTenSecondsPassed(true);
      }, 10000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [tenSecondsPassed]);

  // ten seconds otherwise it beeps twice
  useEffect(() => {
    if (activeProspectOnPhoneNowId && activeProspectOnPhoneNowIdNotification !== activeProspectOnPhoneNowId && multi && tenSecondsPassed && !stopDialing) {
      console.log("beeped", activeProspectOnPhoneNowIdNotification, activeProspectOnPhoneNowId);
      dispatch(setStopDialing(true));
      setActiveProspectOnPhoneNowIdNotification(activeProspectOnPhoneNowId);
      setTenSecondsPassed(false);
      // new Audio(audio).play();
      // console.log("device using changes 12222audio");
    }
  }, [activeProspectOnPhoneNowId]);

  // single mode

  useEffect(() => {
    if (!multi) {
      if (token && isTwilioDialerSetUp !== "READY") {
        const device = new Device();

        device.setup(token, { debug: true, ringtone: false });
        // device.setup(token, { debug: true });
        device.on("ready", () => {
          setDeviceUsing(device);
          setIsTwilioDialerSetUp("READY");
        });
        console.log("device using changes 4444");
        // device.connect({ callsid: 'd' });
        return () => {
          if (deviceUsing) {
            deviceUsing.disconnectAll();
          }
          // setDeviceUsing(null);
          setIsTwilioDialerSetUp("OFFLINE");
        };
      } else if (!token) {
        dispatch(getTwilioVoiceToken());
      }
    }
  }, [token]);

  useEffect(() => {
    if (dialerStatus === "OFFLINE" && !multi && selectedPhoneCallFromSingleMode) {
      setDialerStatus("call");
    }
  }, [token, multi]);

  useEffect(() => {
    console.log(phone_num_of_cli, dialerStatus === "call", dialerStatus, deviceUsing, phoneNumberToCallInfo?.phone, !multi, !conn, "whats in here??");
    if (token && phone_num_of_cli && dialerStatus === "call" && deviceUsing && phoneNumberToCallInfo?.phone && !multi && !conn) {
      console.log("device using changes 55555");
      setDialerStatus("RINGING");
      if (deviceUsing.activeConnection()) {
        console.log("disconsnenecting 6");
        // Disconnect the active connection before making a new one
        deviceUsing.disconnectAll();
      }
      deviceUsing.connect({
        from: phone_num_of_cli,
        To: `+${phoneNumberToCallInfo?.phone}`,
        caller: dialerPowerDialingOnInfo?.overview?.campId
          ? `${phoneNumberToCallInfo?.prosId}|${dialerPowerDialingOnInfo?.overview?.campId}`
          : phoneNumberToCallInfo?.prosId
          ? // manual dialer so its pros id and not pros in campaign id
            `_${phoneNumberToCallInfo?.prosId}_`
          : null,
      });
    } else if (dialerStatus === "OFFLINE" && !multi) {
      dispatch(getTwilioVoiceToken());
    }
  }, [token, phone_num_of_cli, multi, dialerStatus, deviceUsing, phoneNumberToCallInfo, conn]);

  return <></>;
};
// export default TwilioComponent;
