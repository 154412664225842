import { Button, Checkbox, Col, Flex, Popover, Row, Tooltip, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { ConfirmButtons } from "src/components/ui";
import { CustomTag } from "src/components/ui/CustomTag";
import { SvgIcon } from "src/shared/icons";
import { CallNotesModal } from "../CallNotesModal";
import { numberToDial } from "src/store/dialer";
import { useDispatch, useSelector } from "react-redux";
import { changeIsContinuosCallingBe } from "src/store/dialer/asyncRequests";
import { changeIsContinuosCalling, outreachPhonesSelector } from "src/store/outreach-phones";

const phoneButtons = [
  {
    value: "1",
    letters: "",
  },
  {
    value: "2",
    letters: "ABC",
  },
  {
    value: "3",
    letters: "DEF",
  },
  {
    value: "4",
    letters: "GHI",
  },
  {
    value: "5",
    letters: "JKL",
  },
  {
    value: "6",
    letters: "MNO",
  },
  {
    value: "7",
    letters: "PQRS",
  },
  {
    value: "8",
    letters: "TUV",
  },
  {
    value: "9",
    letters: "WXYZ",
  },
  {
    value: "*",
    letters: "",
  },
  {
    value: "0",
    letters: "",
  },
  {
    value: "#",
    letters: "",
  },
];

export const DialerButtonsFooter: FC<{
  closeModal: VoidFunction;
  onHangUp: VoidFunction;
  onHangUpAndClose: VoidFunction;
  callBackFunction: VoidFunction;
  callSeconds: number;
  allowShowNotes?: number;
  addNotes: boolean;
  disabled?: boolean;
  manualDialer?: boolean;
}> = ({ callBackFunction, closeModal, onHangUp, callSeconds, onHangUpAndClose, addNotes, manualDialer, disabled, allowShowNotes }) => {
  const dispatch = useDispatch();
  const [openPopover, setOpenPopover] = useState(false);
  const [openCallNotesModal, setOpenCallNotesModal] = useState(false);
  const { is_continuos_calls } = useSelector(outreachPhonesSelector);
  const handleIsContinuosCallingCheckboxChange = (e) => {
    const value = e.target.checked;
    dispatch(changeIsContinuosCalling(value));
    dispatch(changeIsContinuosCallingBe({ value }));
  };
  const handleNumberClick = (number) => {
    console.log(number, "numberToDialOnCall2");
    dispatch(numberToDial(number));
  };
  const popoverContent = (
    <Row className={"phone-numbers-wrapper"} gutter={[0, 16]}>
      {phoneButtons.map((item, index) => {
        return (
          <Col key={`${item.value}-${index}`} span={8} style={{ textAlign: "center" }}>
            <Flex className={"phone-numbers-button"} vertical align={"center"} gap={4} onClick={() => handleNumberClick(item.value)}>
              <Typography.Paragraph className={"phone-number"}>{item.value}</Typography.Paragraph>
              {item.letters ? <Typography.Paragraph className={"phone-letters"}>{item.letters}</Typography.Paragraph> : null}
            </Flex>
          </Col>
        );
      })}
    </Row>
  );

  const showCallNotesModal = () => {
    setOpenCallNotesModal(true);
  };
  const closeCallNotesModal = () => {
    setOpenCallNotesModal(false);
  };
  useEffect(() => {
    if (addNotes) {
      showCallNotesModal();
    }
  }, [addNotes]);
  const handleOpenChange = (newOpen: boolean) => {
    setOpenPopover(newOpen);
  };
  const secondsToCallTime = (seconds) => {
    const minutes = Math.floor((seconds || 0) / 60);
    const remainingSeconds = seconds % 60;
    return `Call Time: ${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <>
      <div>
        <Flex justify={"space-between"} align={"center"} style={{ marginTop: 28 }}>
          {!!allowShowNotes ? (
            <Button className={"link"} onClick={showCallNotesModal}>
              Add notes
            </Button>
          ) : (
            <Tooltip
              title={`Can't add notes to an anonymous call. If this is a prospect reupload them in the "Contacts" tab with their number to update them.`}
            >
              <Button className={"link"} style={{ cursor: "default", color: "grey" }}>
                Add notes
              </Button>
            </Tooltip>
          )}
          <Row style={{ marginLeft: "11%", width: "fit-content" }}>
            {/* {phoneCalling ? ( */}
            {/* <Tooltip title={``}> */}
            {/* {" "} */}
            <CustomTag text={secondsToCallTime(callSeconds)} color={"#00ADEE"} backgroundColor={"#00ADEE1A"} style={{ marginRight: "30px" }} />
            {/* <CustomTag text={"Call Time: 00:28"} color={"#00ADEE"} backgroundColor={"#00ADEE1A"} style={{ marginRight: "30px" }} /> */}
            {/* </Tooltip> */}
            {/* ) : (
            <CustomTag text={"Call Time: 00:28"} color={"#00ADEE"} backgroundColor={"#00ADEE1A"} style={{ marginRight: "30px" }} />
          )} */}
            <Popover content={popoverContent} trigger={"click"} open={openPopover} arrow={false} style={{ width: 300 }} onOpenChange={handleOpenChange}>
              <SvgIcon type={"phoneDialer"} onClick={() => setOpenPopover(true)} style={{ cursor: "pointer" }} />
            </Popover>
          </Row>
          <ConfirmButtons
            width={200}
            rightButtonTitle={manualDialer ? "Call" : "Call Back"}
            leftButtonTitle={"End Call"}
            onCancel={onHangUp}
            handleSubmit={callBackFunction}
            disabled={disabled}
          />
        </Flex>
        {!manualDialer ? (
          <Flex align={"center"} style={{ marginTop: 14 }}>
            <Checkbox style={{ marginRight: "10px" }} checked={is_continuos_calls} onChange={handleIsContinuosCallingCheckboxChange} /> Continuous dialing{" "}
            <Tooltip
              placement={"top"}
              title={
                <>
                  This option will automatically dial the next numbers in the list after this window is closed. If you want to stop dialing automatically, turn
                  off this option.
                </>
              }
            >
              {" "}
              <SvgIcon type={"tooltip"} style={{ marginBottom: "5px", marginLeft: 5, cursor: "pointer", verticalAlign: "middle" }} />
            </Tooltip>
          </Flex>
        ) : (
          ""
        )}
      </div>
      <CallNotesModal open={openCallNotesModal} onCancel={closeCallNotesModal} hangup={onHangUpAndClose} prosIdFromManual={allowShowNotes} />
    </>
  );
};
