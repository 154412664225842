import { Avatar, Button, Dropdown, Flex, Input, MenuProps, Progress, Row, Typography } from "antd";
import React, { FC, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../shared/icons";
import { ConfirmButtons } from "../ui";
// import { AssistantSettingsDrawer } from "./AssistantSettingsDrawer"
import { DialerModal, NotificationDropdown, PlayerDropdown } from "./ui";
import { ViewModal } from "./ui/ViewModal";
import "./DefaultHeader.less";
import { CommonContext } from "../app/context/CommonContext";
import { ConnectSpotifyModal } from "../pages/phone-calls";
import { fullUserSelector, logout, setSkipTrial, userSelector } from "src/store/user";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { ActivateForAccessModal } from "../pages/prospect-data";
import { outreachPhonesSelector } from "src/store/outreach-phones";

const initialCreditData = {
  prospect: {
    left: 209,
    total: 250,
  },
  emails: {
    left: 242,
    total: 250,
  },
  isTrial: false,
};

export const DefaultHeader: FC = () => {
  const { currentView } = useContext(CommonContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [openDialerModal, setOpenDialerModal] = useState(false);
  const [isViewModalOpened, setIsViewModalOpened] = useState(false);
  const [creditsData, setCreditsData] = useState(initialCreditData);
  let {
    first_name,
    icon,
    split_between_prosp_to_personalize,
    new_prospectdata_or_personalized_email_permonth,
    ai_personalized_emails_left,
    prospect_data_credits,
    new_payment_status,
    trial_credits,
  } = useSelector(userSelector);
  const { phone_numbers } = useSelector(outreachPhonesSelector);
  let { openActivateModalTrialSkip } = useSelector(fullUserSelector);
  // console.log(openActivateModalTrialSkip, "openActivateModalTrialSkip");
  const closeSkipModal = () => {
    dispatch(setSkipTrial(false));
  };
  const isTrial = new_payment_status === "trial";
  if (isTrial) {
    const credUsedAi = (1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth - ai_personalized_emails_left;
    ai_personalized_emails_left = trial_credits - credUsedAi;
    if (ai_personalized_emails_left < 0) {
      ai_personalized_emails_left = 0;
    }
    const credUsed = (1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth - prospect_data_credits;
    prospect_data_credits = trial_credits - credUsed;
    if (prospect_data_credits < 0) {
      prospect_data_credits = 0;
    }
    // keep this at end
    new_prospectdata_or_personalized_email_permonth = trial_credits * 2;
  }

  const showDialerModal = () => {
    setOpenDialerModal(true);
  };

  const closeDialerModal = () => {
    setOpenDialerModal(false);
  };

  const showViewModal = () => {
    setIsViewModalOpened(true);
  };
  const closeViewModal = () => {
    setIsViewModalOpened(false);
  };
  const suffix = (
    <Row style={{ gap: 4 }}>
      <SvgIcon type={"searchSuffixIcon"} />
      <SvgIcon type={"searchSuffixIconK"} />
    </Row>
  );
  const dispatch = useDispatch<AppDispatch>();

  const items: MenuProps["items"] = [
    // {
    //   label: (
    //     <Flex vertical={true} gap={8} className={"profile-item-wrapper"}>
    //       <Typography.Paragraph className={"profile-menu-title"}>Prospect Data Credits</Typography.Paragraph>
    //       <Progress
    //         // there is business logic for a future feature that might come in that explains why percent is complex equation
    //         percent={((prospect_data_credits || 0) / ((1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth)) * 100}
    //         strokeColor={"#01AEEF"}
    //         trailColor={"#EDFCFD"}
    //         showInfo={false}
    //         size={{ width: 210 }}
    //       />
    //       <Flex vertical={true} gap={4}>
    //         <Typography.Paragraph className={"profile-menu-value"}>
    //           {isTrial && `Trial `}Prospect Data Left: {prospect_data_credits || 0}
    //         </Typography.Paragraph>
    //         <Typography.Paragraph className={"profile-menu-value"}>
    //           Total Prospect Data: {(1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth}
    //         </Typography.Paragraph>
    //       </Flex>
    //     </Flex>
    //   ),
    //   key: "0",
    //   style: { pointerEvents: "none" },
    // },
    {
      style: { pointerEvents: "none" },
      label: (
        <Flex vertical={true} gap={8} className={"profile-item-wrapper"}>
          <Typography.Paragraph className={"profile-menu-title"}>AI Personalized Emails</Typography.Paragraph>
          <Progress
            percent={
              ((ai_personalized_emails_left || 0) / ((1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth)) * 100
            }
            // percent={((creditsData.emails.total - creditsData.emails.left) * 100) / creditsData.emails.total}
            strokeColor={"#01AEEF"}
            trailColor={"#EDFCFD"}
            showInfo={false}
            size={{ width: 210 }}
          />
          <Flex vertical={true} gap={4}>
            <Typography.Paragraph className={"profile-menu-value"}>
              {isTrial && `Trial `}AI Emails Left: {ai_personalized_emails_left || 0}
            </Typography.Paragraph>
            <Typography.Paragraph className={"profile-menu-value"}>
              Total AI Emails: {(1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth}
            </Typography.Paragraph>
          </Flex>
        </Flex>
      ),
      key: "1",
    },

    !!isTrial && {
      // style: { pointerEvents: "none" },
      label: (
        <Flex vertical>
          {" "}
          {isTrial && (
            <ConfirmButtons
              rightButtonTitle={"Skip trial"}
              handleSubmit={() => dispatch(setSkipTrial(true))}
              className={"skip-trial-wrapper"}
              style={{ margin: "0 0 20px 0" }}
            />
          )}
        </Flex>
      ),
      key: "2",
    },
    // {
    //   label: (
    //     <Flex
    //       align={"center"}
    //       gap={8}
    //       className={"profile-item-wrapper"}
    //       onClick={() => setIsAssistantSettingsDrawerOpened(true)}
    //     >
    //       <SvgIcon type={"settings"} />
    //       <Typography.Paragraph className={"profile-menu-title"}>Assistant Settings</Typography.Paragraph>
    //     </Flex>
    //   ),
    //   key: "2",
    // },
    // { TODOF
    //   label: (
    //     <Flex
    //       align={"center"}
    //       gap={8}
    //       className={"profile-item-wrapper"}
    //       onClick={showViewModal}
    //     >
    //       <SvgIcon type={"view"} width={20} height={20} />
    //       <Typography.Paragraph className={"profile-menu-title"}>View: {currentView}</Typography.Paragraph>
    //     </Flex>
    //   ),
    //   key: "2",
    // },

    {
      label: (
        <Link to={"/settings"}>
          <Flex align={"center"} gap={8} className={"profile-item-wrapper"}>
            <SvgIcon type={"settings"} width={20} height={20} />
            <Typography.Paragraph className={"profile-menu-title"}>Settings</Typography.Paragraph>
          </Flex>
        </Link>
      ),
      key: "4",
    },
    {
      label: (
        <Flex
          align={"center"}
          gap={8}
          className={"profile-item-wrapper"}
          onClick={() => {
            dispatch(logout({}));
            window.location.href = "/login";
          }}
        >
          <SvgIcon type={"undo"} width={20} height={20} />
          <Typography.Paragraph className={"profile-menu-title"}>Logout</Typography.Paragraph>
        </Flex>
      ),
      key: "5",
    },
  ]?.filter(Boolean);

  return (
    <React.Fragment>
      <Row className={"header-wrapper"} style={{ padding: "20px 40px" }} justify={"space-between"} wrap={false}>
        <div />
        {/* <Input.Search
          className={"search-input"}
          placeholder={"Search..."}
          allowClear
          prefix={<SvgIcon type={"search"} />}
          suffix={suffix}
          enterButton={false}
          variant={"borderless"}
        /> TODOF add later general searhc to find prospects/campaigns etc. */}
        <Row align={"middle"} wrap={false} style={{ gap: 16 }}>
          {!!phone_numbers?.filter((num) => num.is_active)?.length && <SvgIcon type={"phone"} onClick={showDialerModal} style={{ cursor: "pointer" }} />}
          <PlayerDropdown />
          {/* <NotificationDropdown /> */}
          <Row align={"middle"} wrap={false} style={{ gap: 8 }}>
            <Dropdown menu={{ items }} trigger={["click"]} rootClassName={"profile-dropdown"} onOpenChange={(open) => setDropdownOpen(open)}>
              <a onClick={(e) => e.preventDefault()}>
                <Flex align={"center"} gap={8}>
                  <Flex className={"header-avatar-wrapper"} style={{ width: currentView === "Personal" ? 40 : 66 }}>
                    <Avatar
                      className={"header-avatar"}
                      size={"large"}
                      src={icon}
                      // icon={currentView === "Team" ? false : currentView === "Personal" ? <SvgIcon type={"womanAvatar"} /> : <SvgIcon type={"manAvatar"} width={40} height={40} />}
                      style={{
                        width: 35,
                        height: 35,
                        backgroundColor: currentView === "Team" ? "#FFF" : "#757A891A",
                        border: currentView === "Team" ? "1px solid #01AEEF" : "unset",
                      }}
                    >
                      {currentView === "Team" && "T"}
                    </Avatar>
                    {/*
                    <Avatar
                      className={"header-avatar"}
                      size={"large"}
                      icon={currentView === "Team" ? false : currentView === "Personal" ? <SvgIcon type={"womanAvatar"} /> : <SvgIcon type={"manAvatar"} width={40} height={40} />}
                      style={{ backgroundColor: currentView === "Team" ? "#FFF" : "#757A891A", border: currentView === "Team" ? "1px solid #01AEEF" : "unset"}}
                    >
                      {currentView === "Team" && "T"}
                    </Avatar>
                 
                  {currentView !== "Personal" && (
                      <Avatar className={"header-avatar"} size={"large"} icon={<SvgIcon type={"womanAvatar"} width={40} height={40} />} />
                    )} */}
                  </Flex>
                  <Row align={"middle"} style={{ gap: 6 }}>
                    <Typography.Paragraph className={"profile-username"}>{first_name || "Loading account..."}</Typography.Paragraph>
                    <SvgIcon type={"arrowHeader"} style={{ transform: dropdownOpen ? "rotate(180deg)" : "rotate(0deg)" }} />
                  </Row>
                </Flex>
              </a>
            </Dropdown>
          </Row>
        </Row>
      </Row>
      {isViewModalOpened && <ViewModal onClose={closeViewModal} />}
      <DialerModal open={openDialerModal} onCancel={closeDialerModal} reopenThis={showDialerModal} />
      <ActivateForAccessModal open={openActivateModalTrialSkip} onCancel={closeSkipModal} />
    </React.Fragment>
  );
};
