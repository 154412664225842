/* eslint-disable react/jsx-fragments */
import { Button, Checkbox, CheckboxProps, Flex, Form, Input, Typography, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Collapse, ContentCard, CustomLabel, TextEditor } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { AddVariableModal } from "../AddVariableModal";
import { CreateVariantsModal } from "../CreateVariantsModal";
import { CollapseProps } from "antd/lib";
import { useDispatch, useSelector } from "react-redux";
import { doneReplacedStepWithNewEmailVariants, outreachCampaignSelector, setInvalidSteps } from "src/store/outreach-campaigns";

export const ManualEmails = ({ idOfEditingEmail, draftOutreachSequence, setDraftOutreachSequence, madeChanges, setMadeChanges, from }) => {
  const dispatch = useDispatch();
  const { newlyGeneratedTemplatedReplace, newlyGeneratedTemplatedEmails, invalidDraftStepsScratch, invalidDraftStepsAiGen, invalidDraftStepsEditCampaign } =
    useSelector(outreachCampaignSelector);
  const [stepNumber, setStepNumber] = useState(1);

  //  need 2 different ones so it doesnt rerender map and redo the content change and start looping rerenders
  const [subjectlines, setSubjectlines] = useState([]);
  const [stepEditingNewVal, setStepEditingNewVal] = useState({});
  const [controlActKeys, setControlActKeys] = useState([]);
  const [stepEditing, setStepEditing] = useState({});
  // const [randomControledKey, setRandomControledKey] = useState(0);

  const [canHaveReplyTo, setCanHaveReplyTo] = useState(false);

  const checkForErrors = (id, emailvariants: any[]) => {
    if (!id) {
      return;
    }
    let hasAtLeastOneEmailWithEmailBody = false;
    emailvariants?.forEach((variant) => {
      if (variant?.body?.trim()?.length > 10) {
        hasAtLeastOneEmailWithEmailBody = true;
      }
    });
    // console.log(hasAtLeastOneEmailWithEmailBody, "hasAtLeastOneEmailWithEmailBody");
    const invalidSteps = from === "ai" ? invalidDraftStepsAiGen : from === "scratch" ? invalidDraftStepsScratch : invalidDraftStepsEditCampaign;
    const inInvalidInd = (invalidSteps as any[])?.findIndex((inv) => inv?.id === id);
    const copInvalidSteps = [...(invalidSteps || [])];
    if (hasAtLeastOneEmailWithEmailBody && inInvalidInd !== -1) {
      copInvalidSteps.splice(inInvalidInd, 1);
      dispatch(setInvalidSteps({ copInvalidSteps, from }));
    } else if (!hasAtLeastOneEmailWithEmailBody && inInvalidInd === -1) {
      copInvalidSteps.push({ id, stepIssue: `Templated Email` });
      dispatch(setInvalidSteps({ copInvalidSteps, from }));
    }
  };
  console.log(draftOutreachSequence, "draftOutreachSequence");
  const save = ({ isClickedSave, addOnePls, indexToDelAddedOne }: { isClickedSave: boolean; addOnePls?: boolean; indexToDelAddedOne?: number }) => {
    const updatedDraftSeq = draftOutreachSequence?.map((step) => {
      if (step.id === stepEditingNewVal?.id) {
        const emailVar =
          stepEditingNewVal?.emailVariantsArray
            ?.map((variant, index) => {
              const subjectLinesOfSubj = subjectlines?.find((subjectVariant) => subjectVariant?.ind === index);

              const subject = !(subjectLinesOfSubj?.subject || subjectLinesOfSubj?.oldSubject)?.trim()?.length
                ? "Would this be of interest?"
                : subjectLinesOfSubj.subject || subjectLinesOfSubj.oldSubject;

              return { ...variant, subject }; // Correctly return the updated variant
            })
            ?.filter((_, index) => index !== (indexToDelAddedOne || 0) - 1) || [];

        if (addOnePls) {
          emailVar.push({ body: "", subject: "" });
        }

        const updatedNewValWithSubj = { ...stepEditingNewVal, emailVariantsArray: emailVar };
        // console.log(stepEditingNewVal, emailVar, "stepEditingNewValstepEditingNewVal");
        checkForErrors(step.id, emailVar);
        // add logic here to check if there is a variant with a body and if not so add id to error otherwise if its fine so remove it from the array
        return { ...updatedNewValWithSubj, updFromTemplates: true };
      }
      return { ...step, updFromTemplates: true };
    });
    // console.log(updatedDraftSeq, "updatedDraftSeq");
    setDraftOutreachSequence(updatedDraftSeq);

    if (isClickedSave) {
      message.success("Updated Templates");
    }
    setMadeChanges(false);
  };

  const addOne = () => {
    // console.log("save4");
    save({ isClickedSave: false, addOnePls: true });
    setControlActKeys([]);
    message.destroy();
    message.success(`Added an additional variation`);
    setTimeout(() => {
      const newInd = (stepEditing?.emailVariantsArray || 0)?.length;
      setControlActKeys([`${idOfEditingEmail || ""}${String.fromCharCode(65 + newInd)}`]);
      message.info({
        content: "Don't forget to add a new email template in the variation",
        duration: 7, // Duration in seconds
      });
    }, 1000);
  };

  const deleteVariant = (indexToDel) => {
    // console.log("save3");
    save({ isClickedSave: false, indexToDelAddedOne: indexToDel + 1 });
    setControlActKeys([]);
    message.destroy();
    message.success(`Erased The Original "Variation ${String.fromCharCode(65 + indexToDel)}"`);
    setTimeout(() => {
      message.info({
        content: "Finished reassigning variation letters",
        duration: 7, // Duration in seconds
      });
    }, 1000);
  };

  const { Text } = Typography;

  useEffect(() => {
    // console.log(madeChanges, "madeChanges save", draftOutreachSequence);
    if (!madeChanges) {
      if (draftOutreachSequence?.length && draftOutreachSequence[0]?.updFromTemplates) {
        // otherwise wil lrerender forever
        return;
      }
      // console.log("save2");
      save({ isClickedSave: false });
    }
  }, [madeChanges]);

  useEffect(() => {
    if (stepEditing?.id) {
      if (draftOutreachSequence?.length && draftOutreachSequence[0]?.updFromTemplates) {
        // otherwise wil lrerender forever since save triggers this
        // return
      } else {
        // console.log("save1");
        save({ isClickedSave: false });
        setControlActKeys([]);
      }
    }
  }, [idOfEditingEmail]);
  useEffect(() => {
    // this logic moved to above useEffect (it is not good moving between steps works good, however if i change left screen while this component on right side it will cause it to save quickly the old settings, so i only count on Id change to trigger the above save)
    if (stepEditing?.id) {
      // if (draftOutreachSequence?.length && draftOutreachSequence[0]?.updFromTemplates) {
      //   // otherwise wil lrerender forever since save triggers this
      //   // return
      // } else {
      //   // console.log("save1");
      //   save({ isClickedSave: false });
      //   setControlActKeys([]);
      // }
    }
    setSubjectlines([]);
    setStepEditing({});
    setStepEditingNewVal({});
    // console.log(draftOutreachSequence,'draftOutreachSequence');
    setCanHaveReplyTo(false);
    let editingStep = {};
    draftOutreachSequence?.map((step, indexOfStep) => {
      if (!editingStep?.id && (step.mode === "Ai Personalized Email" || step.mode === "Template Email")) {
        setCanHaveReplyTo(true);
      }
      if (step.id === idOfEditingEmail) {
        editingStep = step;
        // console.log(editingStep, editingStep?.autoStart, "stepstepstepstep");
        setStepNumber(indexOfStep + 1);
      }
    });
    const subjectLines = editingStep?.emailVariantsArray?.length
      ? editingStep?.emailVariantsArray?.map((step, ind) => ({ ind, subject: step?.subject, oldSubject: step?.subject }))
      : [];
    setSubjectlines(subjectLines);
    setStepEditing(editingStep);
    setStepEditingNewVal(editingStep);
  }, [draftOutreachSequence, idOfEditingEmail]);

  useEffect(() => {
    if (newlyGeneratedTemplatedReplace) {
      const updatedDraftSeq = draftOutreachSequence?.map((step) => {
        if (step.id === stepEditingNewVal?.id) {
          // console.log(newlyGeneratedTemplatedEmails, "newlyGeneratedTemplatedEmails2");
          const replacementVariants = newlyGeneratedTemplatedEmails?.map((variant) => {
            const varCopy = { ...variant };

            varCopy.subject = canHaveReplyTo ? variant.subject : variant.subject === "ReplyTo" ? "Would this be of interest?" : variant.subject;

            return varCopy;
          });

          const updatedNewValWithSubj = { ...stepEditingNewVal, emailVariantsArray: replacementVariants };

          return { ...updatedNewValWithSubj, updFromTemplates: true };
        }
        return { ...step, updFromTemplates: true };
      });

      setDraftOutreachSequence(updatedDraftSeq);
      dispatch(doneReplacedStepWithNewEmailVariants());
    }
  }, [newlyGeneratedTemplatedReplace]);

  const [openModal, setOpenModal] = useState(false);
  const [openCreateVariantsModal, setOpenCreateVariantsModal] = useState(false);

  const showModal = () => {
    setOpenModal(true);
  };

  const showCreateVariantsModal = () => {
    setOpenCreateVariantsModal(true);
  };
  const onCancel = () => {
    setOpenModal(false);
    setOpenCreateVariantsModal(false);
  };

  const fakeFirstName = "Mark";
  const fakeTitle = "CEO";
  const fakeCompanyName = "Acme.com";

  const introduceSpellingMistake = (name) => {
    const index = Math.floor(Math.random() * name.length);
    if (Math.random() > 0.5) {
      // Remove a character
      return name.slice(0, index) + name.slice(index + 1);
    } else {
      // Add a random character
      const randomChar = String.fromCharCode(97 + Math.floor(Math.random() * 26));
      return name.slice(0, index) + randomChar + name.slice(index);
    }
  };

  const removeCompanyInitials = (name) => {
    return name?.replace(/\.com/g, "");
  };

  const processReplacement = (match, type, alt, nat) => {
    // let natValues = [];
    // try {console.log(nat,'natnatnat',match);
    //    const natValues = match?.length>2 ? match[2].split(','):[];
    //   console.log("nat values:", natValues);
    //   if (typeof nat === 'string') {
    //   natValues = nat.split(',').map(Number);
    // }
    // } catch (error) {
    //   console.error("Error parsing nat values:", error);
    // }

    let replacement;
    if (type === "first_name") {
      replacement = fakeFirstName;
    } else if (type === "title") {
      replacement = fakeTitle;
    } else if (type === "company_name") {
      replacement = fakeCompanyName;
    }
    console.log(match, "matchmatch");
    // Apply transformations based on nat values
    if (match?.includes("4")) {
      replacement = replacement.toLowerCase();
    }
    if (match?.includes("1")) {
      replacement = introduceSpellingMistake(replacement);
    }
    if (match?.includes("2") && type === "company_name") {
      replacement = removeCompanyInitials(replacement);
    }

    return replacement;
  };

  const changeOutVariables = (inputString) => {
    return inputString?.replace(/{{var:(first_name|title|company_name);alt:[^;]+;nat:([^}]+)}}/g, processReplacement);
  };

  const handleBodyUpd = (body, indChange, isClientChange) => {
    // console.log(body,'updatedDraftSeq1');
    const emailVariantsArray = stepEditing?.emailVariantsArray?.map((variant, index) => {
      if (index === indChange) {
        return { ...variant, body };
      }

      return variant;
    });

    setStepEditingNewVal({ ...stepEditing, emailVariantsArray });
    if (isClientChange) {
      setMadeChanges(true);
    }
  };

  useEffect(() => {
    if (stepEditing?.emailVariantsArray?.length) {
      stepEditing?.emailVariantsArray?.forEach((email, i) => {
        handleBodyUpd(email.body, i, false); // to initiate and will add it to the example upon render + will make error work well. Checks should be done BOTH here and in the "save" for errors
      });
    }
    // }
    // console.log(stepEditing, "stepEditingstepEditing");
    checkForErrors(stepEditing.id, stepEditing?.emailVariantsArray);
  }, [stepEditing]);

  const emails: CollapseProps["items"] = stepEditing?.emailVariantsArray?.length
    ? stepEditing?.emailVariantsArray?.map((email, i) => {
        // console.log();
        const updatedContent = changeOutVariables(
          stepEditingNewVal?.emailVariantsArray?.length ? stepEditingNewVal?.emailVariantsArray?.find((newEmailVal, ind) => i === ind)?.body || "" : ""
        );

        let subject = changeOutVariables(subjectlines?.length ? subjectlines?.find((newEmailVal, ind) => i === ind)?.subject : "");

        subject = canHaveReplyTo ? subject : subject === "ReplyTo" ? "Would this be of interest?" : subject;

        const handleSubjUpd = (subject, indChange) => {
          // console.log(subject, "updatedDraftSeq1");
          const subjectLines = subjectlines?.map((variant, index) => {
            if (variant?.ind === indChange) {
              return { ...variant, subject };
            }

            return variant;
          });

          setSubjectlines(subjectLines);
          setMadeChanges(true);
        };

        const toggleSubject = (val, indChange) => {
          if (val) {
            handleSubjUpd("ReplyTo", indChange);
          } else {
            // console.log(subjectlines, "subjectLinesOfSubj1");
            const subjectLinesOfSubj = subjectlines?.find((variant) => variant?.ind === indChange);
            // console.log(subjectLinesOfSubj, "subjectLinesOfSubj");

            const subjectLine =
              (subjectLinesOfSubj?.oldSubject || subjectLinesOfSubj?.subject) === "ReplyTo"
                ? "Would this be of interest?"
                : subjectLinesOfSubj?.oldSubject || subjectLinesOfSubj?.subject;

            handleSubjUpd(subjectLine, indChange);
          }
        };

        console.log(email.body, "email.body?.trim()?.length", updatedContent);
        return {
          key: `${idOfEditingEmail || ""}${String.fromCharCode(65 + i)}`,
          label: (
            <>
              Email Variation {String.fromCharCode(65 + i)}{" "}
              {updatedContent?.trim()?.length > 10 ? `` : <span style={{ color: "orange", marginLeft: "10px" }}> - Empty, please add an email template</span>}
            </>
          ),
          children: (
            <>
              <Flex justify="space-between" align="center">
                {canHaveReplyTo ? (
                  <Checkbox checked={subject?.trim() === "ReplyTo"} onChange={(e) => toggleSubject(e.target.checked, i)}>
                    Reply to previous steps email
                  </Checkbox>
                ) : (
                  <div />
                )}
                <Button className={"link"} onClick={() => deleteVariant(i)}>
                  Delete Variant {String.fromCharCode(65 + i)}
                </Button>
              </Flex>
              <br />
              {subject?.trim() !== "ReplyTo" && (
                <>
                  <React.Fragment>
                    <CustomLabel title={"Subject Line"} />
                    <Input value={subject} onChange={(e) => handleSubjUpd(e.target.value, i)} />
                  </React.Fragment>
                  <br />
                  <br />
                </>
              )}
              <CustomLabel title={"Email Body"} />
              <Flex vertical style={{ marginBottom: 22 }}>
                <TextEditor
                  content={email.body}
                  // withVariables
                  onContentChange={(val) => handleBodyUpd(val, i, true)}
                />
              </Flex>
              <CustomLabel title={"Example Output"} />
              {/* updatedContent */}
              <TextEditor content={updatedContent} readOnly />
            </>
          ),
        };
      })
    : [];

  return (
    <ContentCard
      headerIcon={<SvgIcon type={"letter"} />}
      cardTitle={"Templated Email"}
      headerRightContent={
        <Flex justify="center" align="center">
          <Button className={"link"} style={{ marginRight: "15px" }} onClick={showModal}>
            Add Variable
          </Button>
          <Button className={"primary"} onClick={() => save({ isClickedSave: true })} disabled={!madeChanges}>
            Update
          </Button>
        </Flex>
      }
    >
      <CustomLabel title={<>Day {stepEditing?.day} Email Templates</>} />
      {/* BUG handling not critical add what if there is no emailsubject/variants? shouldnt be to critical now */}
      <br /> <Collapse multiOpen items={emails} controlActKeys={controlActKeys} setControlActKeys={setControlActKeys} />
      <br />
      <CustomLabel title={"Variations Options:"} />
      <Button className={"link"} onClick={() => addOne()} style={{ marginTop: "10px" }}>
        Add 1 More Variation
      </Button>{" "}
      <br />
      <Text>or</Text>
      <br />
      <Button className={"link"} style={{ marginTop: "5px" }} onClick={showCreateVariantsModal}>
        Regenerate all A/B Emails with Ai
      </Button>
      <AddVariableModal open={openModal} onCancel={onCancel} />
      <CreateVariantsModal open={openCreateVariantsModal} onCancel={onCancel} newSeq={from !== "editingCamp"} />
      <br />
      <br />
      <br />
      <Text
        style={{
          fontSize: "12px",
          color: "#897575",
          alignSelf: "flex-end",
          marginTop: "auto",
        }}
      >
        <span style={{ fontWeight: 500, color: "black" }}>How the "Template Email" Step works:</span> Meetz will automatically rotate between template
        variations to identify the best-performing email and will then recommend optimizations for your email templates, leading to more refined A/B tests based
        on the successful template.
      </Text>
    </ContentCard>
  );
};
