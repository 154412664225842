import { Empty, Flex, Form, Image, Input, Radio, Row, Space, Tooltip, Typography } from "antd";
import PhoneInput from "react-phone-input-2";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-phone-input-2/lib/material.css";
import { DialerButtonsFooter } from "src/components/pages/campaigns/CampaignDetails/ui/ParallelDialer/ui/AiGeneratedPersonalIntroModal/ui";
import { debounce } from "lodash";
import { ConfirmButtons, CustomSelect, Loader, Modal, StatusTag, TableHeader } from "src/components/ui";
import { AutoComplete } from "antd";
import { SvgIcon } from "src/shared/icons";
import { SendSmsModal } from "./ui/SendSmsModal";
import "./index.less";
import { TwilioComponent } from "src/components/pages/campaigns/CampaignDetails/ui/ParallelDialer/TwilioComponent";
import { SelectFilterItem } from "src/components/pages/prospect-data/ui/ProspectFilterCollapse/ui";
import { useDispatch, useSelector } from "react-redux";
import { changeIsSelPros, outreachPhonesSelector, resetProsSearch } from "src/store/outreach-phones";
import { manualDialerProsSearch } from "src/store/outreach-phones/asyncRequests";
import noData from "src/shared/images/noData.png";
import { resetVoiceToken } from "src/store/dialer";

type DialerModalProps = {
  open: boolean;
  onCancel: VoidFunction;
  showDialerModal: VoidFunction;
};

const dialerData = [
  {
    id: "1",
    name: "Tom Smith",
    phones: {
      m: "(+1) 435-345-1234",
      d: "(+1) 235-555-1234",
      c: "(+1) 425-665-1884",
    },
  },
];

const numbersToCallFrom = [
  {
    phoneNumber: "(555) 345-4554",
    status: true,
  },
  {
    phoneNumber: "(234) 555-4545",
    status: false,
  },
];

export const getDialerPhoneOptions = (data: any) => {
  return [
    {
      value: data.m,
      label: `Mobile phone ${data.m}`,
    },
    {
      value: data.d,
      label: `Direct phone ${data.d}`,
    },
    {
      value: data.c,
      label: `Company phone ${data.c}`,
    },
  ];
};

export const DialerModal: FC<DialerModalProps> = ({ open, onCancel, showDialerModal }) => {
  const dispatch = useDispatch();
  const [isCalling, setIsCalling] = useState(false);
  // const [openSendSmsModal, setOpenSendSmsModal] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [dialerStatus, setDialerStatus] = useState("call");
  const [deviceUsing, setDeviceUsing] = useState(null);
  const [activatePhoneCall, setActivatePhoneCall] = useState(false);
  const [areYouSureWindow, setAreYouSureWindow] = useState(false);
  const [form] = Form.useForm();
  const [addNotes, setAddNotes] = useState(false);
  const closeModal = (force) => {
    if (!force && activatePhoneCall) {
      setAreYouSureWindow(true);
      return;
    }
    setSeconds(0);
    setIsCalling(false);
    setActivatePhoneCall(false);
    setAddNotes(false);
    // form.resetFields();
    onCancel();
  };

  const [seconds, setSeconds] = useState(0);

  const [isActiveTimer, setIsActiveTimer] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = () => {
    if (!isActiveTimer) {
      setAddNotes(false);
      setSeconds(0);
      setIsActiveTimer(true);
      timerRef.current = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
    }
    console.log(value, "starttimervalue");
    if (selectedPros?.id && selectedPhone) {
      setRecentCallIsWithPros(selectedPros?.id);
    } else {
      setRecentCallIsWithPros(false);
    }
  };

  const stopTimer = () => {
    if (isActiveTimer && timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null; // Reset to null after clearing

      if (seconds > 5 && !!recentCallIsWithPros) {
        setAddNotes(true);
        setTimeout(() => {
          setAddNotes(false);
        }, 2000);
      }
    }
    setIsActiveTimer(false);
  };

  useEffect(() => {
    // console.log(dialerStatus, "dialerStatus");
    if (dialerStatus === "DISCONNECT" || dialerStatus === "OFFLINE") {
      stopTimer(); // Cleanup to avoid memory leaks
    }
    if (dialerStatus === "ON_CALL") {
      stopTimer();
      startTimer();
    }
  }, [dialerStatus]);

  // const showSendSmsModal = () => {
  //   setOpenSendSmsModal(true);
  // };
  // const closeSendSmsModal = () => {
  //   setOpenSendSmsModal(false);
  // };

  const handleFormSubmit = () => {
    setIsCalling(true);
  };

  const debouncedSearch = useCallback(
    debounce((search) => {
      if (search?.trim()?.length) {
        // console.log("manualDialerProsSearch", search);
        dispatch(manualDialerProsSearch({ search }));
      }
    }, 1500),
    []
  );

  const [value, setValue] = useState("None"); // needs none to render the search if nothing was selected

  const [isTwilioDialerSetUp, setIsTwilioDialerSetUp] = useState(false);
  const [recentCallIsWithPros, setRecentCallIsWithPros] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSearch = (value) => {
    // console.log(value, "handleSearch1");
    if (isDropdownOpen) {
      // console.log(value, "valuevalue1");
      setSearchQuery(value);
      debouncedSearch(value);
      if (!value?.trim()?.length) {
        dispatch(resetProsSearch());
      }
    }
  };
  const { loadingPros, searchedPros, selectedPros, phone_numbers } = useSelector(outreachPhonesSelector);

  // const phone_numbers = [
  //   {
  //     id: 16,
  //     phone_number: "+19855204080",
  //     country: "US",
  //     twilio_sid: "PNad2b7f8a46ef07cd3e369f6fcd51d2dd",
  //     is_active: true,
  //   },
  //   {
  //     id: 17,
  //     phone_number: "+121312312312",
  //     country: "US",
  //     twilio_sid: "Padfs46ef07cd3e369f6fcd51d2dd",
  //     is_active: true,
  //   },
  // ];

  const [selectedPhoneCallFrom, setSelectedPhoneCallFrom] = useState(null);

  useEffect(() => {
    setSelectedPhoneCallFrom(phone_numbers?.find((ph) => ph?.is_active)?.phone_number || null);
  }, [phone_numbers]);
  console.log(selectedPhoneCallFrom, "selectedPhoneCallFrom");

  const [selectedPhone, setSelectedPhone] = useState(null);
  const [phoneToCall, setPhoneToCall] = useState(undefined);
  // console.log(phoneToCall, typeof phoneToCall, "phoneToCall2");
  const setPhoneToCallNow = (val) => {
    setSelectedPhone(null);
    setPhoneToCall(val);
  };
  const callPhoneFnction = () => {
    // console.log(num?.phone, "numnum");
    if (!dialerStatus || dialerStatus === "DISCONNECT" || dialerStatus === "OFFLINE") {
      // setPhoneNumberToCallInfo({ phone: num?.phone, prosId: activeProspectOnPhoneNowId });
      setDialerStatus("call");
    }
    setActivatePhoneCall(true);
  };
  const hangUpClose = () => {
    setAreYouSureWindow(false);
    setIsCalling(false);
    setActivatePhoneCall(false);
    setSeconds(0);
    onCancel();
  };
  const updatePhoneToCall = (val) => {
    // setPhoneToCall(undefined);
    // console.log(val, "updatePhoneToCall22");
    setSelectedPhone(val);
    switch (val) {
      case "mobile":
        setPhoneToCall(selectedPros?.mobile_phone);

        break;
      case "direct":
        setPhoneToCall(selectedPros?.direct_phone);
        break;
      case "business":
        setPhoneToCall(selectedPros?.business_number);
        break;
    }
  };

  const [emptyToLoad, setEmptyToLoad] = useState(true);

  // console.log(selectedPros, "selectedPros");
  useEffect(() => {
    if (loadingPros && searchQuery?.trim()?.length) {
      setEmptyToLoad(false);
    }
    if (!searchQuery?.trim()?.length) {
      setEmptyToLoad(true);
    }
  }, [loadingPros, searchQuery]);

  const handleChange = (valueToUpd) => {
    // console.log(e, "handleChangehandleChange");
    // const value = e.target.value;
    setSelectedPhone(null);
    let val = valueToUpd?.[valueToUpd?.length - 1];
    if (val === "_") {
      val = "None";
      dispatch(changeIsSelPros({}));
    } else if (val !== "None") {
      dispatch(changeIsSelPros(searchedPros?.find((pros) => pros?.id === val) || {}));
    }
    setValue(val);

    // debouncedSearch(value);
  };
  const handleDropdownVisibility = (open) => {
    setIsDropdownOpen(open);
  };
  const onHangUpAndClose = () => {
    setSeconds(0);
    if (deviceUsing) {
      deviceUsing.disconnectAll();
      deviceUsing.destroy();
      setDeviceUsing(null);
      dispatch(resetVoiceToken());
    }
    closeModal(true);
    // dispatch(resetMultiDialerPros());
  };
  const onHangUp = () => {
    // setSeconds(0);
    if (deviceUsing) {
      deviceUsing.disconnectAll();
      deviceUsing.destroy();
      setDeviceUsing(null);
      dispatch(resetVoiceToken());
    }
    setActivatePhoneCall(false);

    // dispatch(resetMultiDialerPros());
  };
  // console.log(searchedPros, "searchedProssearchedPros");
  useEffect(() => {
    // console.log(searchQuery, "valuevalue2", searchedPros);
    if (!searchQuery?.trim()?.length && searchedPros?.length) {
      dispatch(resetProsSearch());
    }
  }, [searchedPros, searchQuery]);
  // console.log(searchQuery, "searchQuery", loadingPros);

  const computedOptions = useMemo(() => {
    if (!searchQuery?.trim()?.length) return [];

    let options: any[] = [];

    if (loadingPros) {
      options.push({
        label: (
          <Flex key={999}>
            <div style={{ marginRight: "20px" }}> Loading</div> <Loader size="small" />
          </Flex>
        ),
        value: "_",
      });
    }

    if (searchedPros?.length) {
      options = [
        ...options,
        ...searchedPros
          .filter(Boolean)
          .slice(0, 5)
          .map((prospect, i) => ({
            label: `{{ignore_start}} <div><div style={{ fontWeight: "600", fontSize: "16px", color: "#333"}}>Name:  {{ignore_end}} ${
              prospect.first_name || ""
            } ${prospect.last_name || ""} {{ignore_start}} </div><div style={{ fontSize: "14px", color: "#555" }}>  {{ignore_end}}  ${
              !!prospect.email ? ` {{ignore_start}} Email: {{ignore_end}} ${prospect.email}` : ""
            } {{ignore_start}} </div><div style={{ fontSize: "14px", color: "#555" }}> {{ignore_end}} ${
              !!prospect.company_name ? ` {{ignore_start}} Company: {{ignore_end}} ${prospect.company_name}` : ""
            } {{ignore_start}} </div><div style={{ fontSize: "14px", color: "#555" }}> {{ignore_end}} ${
              !!prospect.title ? ` {{ignore_start}} Job Title: {{ignore_end}} ${prospect.title}` : ""
            } {{ignore_start}} </div>
            <div style={{ fontSize: "14px", color: "#555" }}>
            Phone numbers available: ${!prospect?.mobile_phone && !prospect?.direct_phone && !prospect?.business_number ? `None` : ""}${
              !!prospect?.mobile_phone ? `Mobile ` : ""
            }${!!prospect?.direct_phone ? (!!prospect?.mobile_phone ? `+ Direct ` : `Direct `) : ""}${
              !!prospect?.business_number ? (!!prospect?.direct_phone || !!prospect?.mobile_phone ? `+ Business ` : `Business`) : ""
            }
            </div>
            </div> {{ignore_end}} `,
            value: prospect?.id,
          })),
      ];
    }

    return options;
  }, [searchQuery, loadingPros, searchedPros]);

  useEffect(() => {
    if (activatePhoneCall && dialerStatus === "READY") {
      setDialerStatus("call");
    }
  }, [activatePhoneCall, dialerStatus]);

  return (
    <>
      <Modal
        width={400}
        open={areYouSureWindow}
        title={"Close window? This will hang up on the call."}
        onCancel={() => {
          setAreYouSureWindow(false);
        }}
        zIndex={100000}
      >
        <ConfirmButtons
          className={"confirm-buttons"}
          leftButtonTitle={"Back"}
          rightButtonTitle={"Hang Up & Close"}
          handleSubmit={hangUpClose}
          onCancel={() => {
            setAreYouSureWindow(false);
          }}
        />
      </Modal>
      <Modal
        customClassName={"dialer-modal"}
        // width={400}
        width={575}
        // width={activatePhoneCall ? 575 : 475}
        open={open}
        title={"Manual Dialer"}
        onCancel={closeModal}
      >
        {/* <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit}> */}
        <Typography.Title level={4} style={{ marginBottom: 8 }}>
          Select the recipient of the call
        </Typography.Title>
        <Typography.Paragraph className={"secondary-label-subtitle"} style={{ marginBottom: 12 }}>
          Search Your Leads
        </Typography.Paragraph>
        <CustomSelect
          placeholder={"Search for name, title, or company..."}
          notFoundContent={
            <Empty
              image={<Image preview={false} src={noData} alt={"No data"} width={100} height={100} />}
              imageStyle={{
                height: 120, // Ensure the image height is consistent with its actual height
                marginTop: "10px",
              }}
              description={
                <span style={{ marginTop: "10px" }}>{!searchQuery?.trim()?.length || emptyToLoad ? "Search prospect to dial" : "No prospects found"}</span>
              } // Add margin to separate text from the image
            />
          }
          disabled={activatePhoneCall}
          options={computedOptions}
          placement={"bottomLeft"}
          tagRender={(d) => !isDropdownOpen && <>{searchQuery}</>}
          onChange={handleChange}
          // filterOption={(input, option) => true}
          value={value}
          showSearch
          isSearchSelect
          mode={"multiple"}
          onSearchInComponentToo={handleSearch}
          searchValue={searchQuery}
          forceShowAllOptionsAsOptionsArePrefiltered
          onDropdownVisibleChange={handleDropdownVisibility}
          // disabled
          style={{ marginBottom: "20px", width: "450px" }}
        />

        {!!selectedPros?.id && (
          <>
            <div style={{ marginBottom: "10px" }}>
              <Tooltip
                title={
                  <>
                    Name: {selectedPros.first_name || ""} {selectedPros.last_name || ""}
                    {selectedPros.title && (
                      <>
                        <br /> Job Title: {selectedPros.title}
                      </>
                    )}
                    {selectedPros.company_name && (
                      <>
                        <br /> Company: {selectedPros.company_name}
                      </>
                    )}
                  </>
                }
              >
                <Flex align="center">
                  {" "}
                  <Typography.Title level={4}> Prospect selected:</Typography.Title>{" "}
                  <Typography.Text style={{ marginLeft: "5px" }}>
                    {selectedPros.first_name || ""} {selectedPros.last_name || ""}{" "}
                  </Typography.Text>
                </Flex>
              </Tooltip>
            </div>
            {/* <Form.Item name={"lead"}> */}
            <Radio.Group
              style={{ width: "100%", marginBottom: "25px" }}
              onChange={(e) => updatePhoneToCall(e.target.value)}
              value={selectedPhone}
              disabled={activatePhoneCall}
            >
              <Space direction={"vertical"}>
                {!!selectedPros.mobile_phone && (
                  <Row align={"middle"} wrap={false}>
                    <Radio value={`mobile`}>
                      {selectedPros.mobile_phone}
                      {selectedPros.mobile_phone_extension_num?.length ? ` E: ${selectedPros.mobile_phone_extension_num}` : ""}
                    </Radio>
                    <SvgIcon type={"dividerDot"} style={{ marginRight: 8 }} /> Mobile phone
                  </Row>
                )}
                {!!selectedPros.direct_phone && (
                  <Row align={"middle"} wrap={false}>
                    <Radio value={`direct`}>
                      {selectedPros.direct_phone}
                      {selectedPros.direct_phone_extension_num?.length ? ` E: ${selectedPros.direct_phone_extension_num}` : ""}
                    </Radio>
                    <SvgIcon type={"dividerDot"} style={{ marginRight: 8 }} /> Direct phone
                  </Row>
                )}
                {!!selectedPros.business_number && (
                  <Row align={"middle"} wrap={false}>
                    <Radio value={`business`}>
                      {selectedPros.business_number}{" "}
                      {selectedPros.business_number_extension_num?.length ? ` E: ${selectedPros.business_number_extension_num}` : ""}
                    </Radio>
                    <SvgIcon type={"dividerDot"} style={{ marginRight: 8 }} /> Business phone
                  </Row>
                )}
                {!selectedPros.mobile_phone && !selectedPros.direct_phone && !selectedPros.business_number && (
                  <Row align={"middle"} wrap={false}>
                    This prospect doesn't have a phone number assigned to him.
                  </Row>
                )}
              </Space>
              {/* {dialerData.map((lead, index) => {
                return (
                  <Space key={`${index}-${lead.id}`} direction={"vertical"}>
                    <Row align={"middle"} wrap={false}>
                      <Radio value={lead.id}>{lead.name}</Radio>
                      <SvgIcon type={"dividerDot"} style={{ marginRight: 8 }} />
                       <CustomSelect
                        defaultValue={lead.phones.d}
                        className={"phone-select"}
                        options={getDialerPhoneOptions(lead.phones)}
                        popupClassName={"phone-select-dropdown"}
                      /> 
                    </Row>
                  </Space>
                );
              })}  */}
            </Radio.Group>
            {/* </Form.Item> */}
          </>
        )}
        {/* <Typography.Paragraph className={"empty-data"} style={{ margin: "16px 0" }}>
          or
        </Typography.Paragraph> */}
        {/* <Form.Item className={"phone-number-input"} name={"phoneNumber"} label={"Input Number"} style={{ marginTop: "20px" }}> */}

        <Typography.Title level={4} style={{ marginBottom: "5px" }}>
          Number to call
        </Typography.Title>
        <PhoneInput
          disabled={activatePhoneCall}
          // className={"phone-flags-select"}
          style={{ width: "250px", marginBottom: "35px" }}
          // enableSearch
          value={phoneToCall}
          specialLabel=""
          enableAreaCodes={true}
          // enableSearch
          // value={"111"}
          onChange={setPhoneToCallNow}
          country={"us"}
          // disableCountryGuess

          // style={{ marginBottom: "15px" }}
        />
        {/* </Form.Item> */}
        {phone_numbers?.length > 1 ? <div style={{ marginBottom: "10px" }}>Select number to call from:</div> : ""}
        <Radio.Group style={{ width: "100%", marginBottom: "20px" }} onChange={(e) => setSelectedPhoneCallFrom(e.target.value)}>
          {phone_numbers?.length > 1
            ? phone_numbers?.filter(Boolean)?.map((phn, i) => {
                // console.log(phn, "phnphn", i);

                return (
                  <>
                    <div>
                      <Radio key={phn.phone_number} value={phn.phone_number}>
                        {phn.phone_number}
                      </Radio>
                    </div>
                  </>
                );
              })
            : ""}
        </Radio.Group>
        {/* {numbersToCallFrom && (
        // <Form.Item name={"numberToCallFrom"} label={"Select the number to call from"}>
        <Radio.Group style={{ width: "100%" }}>
          {numbersToCallFrom.map((phone) => (
            <Radio key={phone.phoneNumber} value={phone.phoneNumber}>
              {phone.phoneNumber}
            </Radio>
          ))}
        </Radio.Group>
        // </Form.Item>
      )} */}

        {/* {activatePhoneCall ? ( */}
        <DialerButtonsFooter
          closeModal={closeModal}
          manualDialer
          addNotes={recentCallIsWithPros && addNotes}
          callSeconds={seconds}
          callBackFunction={callPhoneFnction}
          onHangUpAndClose={onHangUpAndClose}
          onHangUp={onHangUp}
          allowShowNotes={recentCallIsWithPros}
          disabled={!phoneToCall?.length || phoneToCall?.length < 10 || !selectedPhoneCallFrom?.length}
        />
        {/* ) : ( */}
        {/* <ConfirmButtons
            manualDialer
            rightButtonTitle={"Call"}
            handleSubmit={() => {
              setActivatePhoneCall(true);
            }}
            leftButtonTitle={"Close"}
            onCancel={closeModal}
            disabled={!phoneToCall?.length || phoneToCall?.length < 10 || !selectedPhoneCallFrom?.length}
          />
        )} */}
        {/* </Form> */}
        {/* <SendSmsModal open={openSendSmsModal} onCancel={closeSendSmsModal} data={dialerData[0]} /> */}
        {!!activatePhoneCall && phoneToCall && phoneToCall?.length >= 10 && (
          <TwilioComponent
            key={1}
            phoneNumberToCallInfo={{
              phone: import.meta.env.VITE_NODE_ENV !== "production" ? `+17204180086` : phoneToCall,
              prosId: recentCallIsWithPros,
            }}
            dialerStatus={dialerStatus}
            setDialerStatus={setDialerStatus}
            // firstCallInProgOrDid={firstCallInProgOrDid} // leave
            // setFirstCallInProgOrDid={setFirstCallInProgOrDid} // leave
            isTwilioDialerSetUp={isTwilioDialerSetUp}
            setIsTwilioDialerSetUp={setIsTwilioDialerSetUp}
            deviceUsing={deviceUsing}
            setDeviceUsing={setDeviceUsing}
            // good
            selectedPhoneCallFromSingleMode={selectedPhoneCallFrom}
          />
        )}
      </Modal>
    </>
  );
};
