import { Button, Col, Empty, Flex, Image, Row, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { MinusOutlined } from "@ant-design/icons";
import { FC, Fragment, useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { PhoneCallsStatus } from "../PhoneCallsStatus";
import { Table, TableHeader } from "src/components/ui";
import { App } from "src/types";
import NoData from "src/shared/images/callingperson.png";
import { phoneCallsData } from "../../info";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import tz from "moment-timezone";
import { outreachPhonesSelector } from "src/store/outreach-phones";
import { SvgIcon } from "src/shared/icons";
import { useNavigate } from "react-router";
import { getPhoneAnalytics } from "src/store/outreach-phones/asyncRequests";
export const PhoneCallsTable: FC = ({ activeId }) => {
  // const [loading, setLoading] = useState(false);
  const { dataAll, phoneLoading: loading } = useSelector(outreachPhonesSelector);

  const formatUnixTimestamp = (unixTimestamp) => {
    const date = tz.unix(unixTimestamp).tz(tz.tz.guess());
    return date.format("MMMM Do 'YY [at] h:mm A");
  };
  const [actCampIds, setActCampIds] = useState([]);

  useEffect(() => {
    const actCamps = dataAll?.campainIdsActive;
    setActCampIds(actCamps || []);
  }, [dataAll]);

  const navigate = useNavigate();
  const columns = [
    {
      title: "Contact name",
      render: (record) => {
        console.log(record, "RECORDqwewqeqwe");
        return record?.prospect === "unknown" ? (
          <Tooltip placement={"top"} title={`This prospect is not in your uploaded contacts, or their number could not be linked to a prospect.`}>
            <MinusOutlined />{" "}
          </Tooltip>
        ) : (
          <Tooltip
            placement={"top"}
            title={
              <>
                <b>Name: </b>
                {record?.prospect?.first_name || ""} {record?.prospect?.last_name || ""}
                {record?.prospect?.job_title ? (
                  <>
                    <br />
                    <b>Job title: </b>
                    {record?.prospect?.job_title}
                  </>
                ) : (
                  ""
                )}
                {record?.prospect?.company_name || record?.prospect?.company_url ? (
                  <>
                    <br />
                    <b> Company: </b>
                    {record?.prospect?.company_name || record?.prospect?.company_url}
                  </>
                ) : (
                  ""
                )}
                {/* <br />
                <b>Company:</b>
                {record?.prospect?.company_name || record?.prospect?.company_url||''} */}
              </>
            }
          >
            {record?.prospect?.first_name} {record?.prospect?.last_name}{" "}
          </Tooltip>
        );
      },
      key: "prospect",
      align: "center",
    },
    // {
    //   title: "Last time contacted",
    //   dataIndex: "lastTimeContacted",
    //   key: "lastTimeContacted",
    //   align: "center",
    // },
    {
      title: "Call Details",
      width: "250px",
      render: (record) => {
        console.log(record, "record");
        const analytics = record?.analyticToShow;
        const isSms = analytics?.dialer_action === "sms_recieved";

        if (!analytics) {
          <MinusOutlined />;
        }
        // const isMyMeetzNum=
        // const whatTypeOfNum=
        const prosNum = activeId === "phone_in" ? analytics?.dialer_number_from : analytics?.dialer_number_to; // myphone number with tool tip show a from and too. and also a nice link they can listen. and add step in campaign if available and deration of call.

        let enter = 150;
        if (analytics?.dialer_sms_message) {
          const newlineIndex = analytics?.dialer_sms_message?.indexOf("\n");
          if (newlineIndex > 1 && newlineIndex < 150) {
            enter = newlineIndex;
          }
        }
        return (
          <Col>
            <Typography.Paragraph style={{ textAlign: "start" }}>
              {/* <Flex> */}
              <b>{activeId === "phone_in" ? (isSms ? "Number that sent you text" : "Number that called you") : "Prospect number"}:</b>{" "}
              {analytics?.prospect_number_type ? (
                <Tooltip placement={"top"} title={analytics?.prospect_number_type}>
                  {prosNum || ""}
                </Tooltip>
              ) : (
                prosNum || ""
              )}
              {isSms ? (
                <>
                  <br />
                  <b>Text:</b>{" "}
                  {analytics?.dialer_sms_message?.length > 150 || enter ? (
                    <Tooltip
                      placement={"right"}
                      title={
                        <div
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((analytics?.dialer_sms_message || "No sms to show").replace(/\n/g, "<br/>")) }}
                        />
                      }
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize((analytics.dialer_sms_message?.substring(0, enter) + "..." || "No sms to show").replace(/\n/g, "<br/>")),
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize((analytics.dialer_sms_message || "No sms to show").replace(/\n/g, "<br/>")) }} />
                  )}
                </>
              ) : (
                <>
                  <br />
                  <b>Duration: </b>
                  {/* {analytics?.dialer_answered ? "yes" : "dsf"} */}
                  {analytics?.dialer_call_duration_seconds && analytics?.dialer_answered ? (
                    <>
                      {Math.floor(analytics?.dialer_call_duration_seconds / 60)}m {analytics?.dialer_call_duration_seconds % 60}s
                    </>
                  ) : (
                    "Not answered"
                  )}
                </>
              )}
              {analytics?.dialer_recording ? (
                <>
                  <br />
                  <Button type={"link"} href={analytics?.dialer_recording} target="_blank" rel="noopener noreferrer">
                    Call Recording
                  </Button>
                </>
              ) : (
                ""
              )}{" "}
              {/* duration, , recording? (If there is),  */}
            </Typography.Paragraph>
          </Col>
        );
      },
      key: "contents",
      align: "center",
    },
    {
      title: "Date Called",
      // dataIndex: "contents", //how long ago
      key: "contents",
      align: "center",
      render: (record) => {
        const analytics = record?.analyticToShow;
        console.log(analytics, "record");
        // campaign_id
        const fixCampName =
          !analytics?.campaign_id || actCampIds?.includes(analytics?.campaign_id) ? analytics?.campaign_name : `${analytics?.campaign_name} (Deleted campaign)`;

        const actCampId = !!analytics?.campaign_id && actCampIds?.includes(analytics?.campaign_id) ? analytics?.campaign_id : null;

        return (
          <Col style={{ gap: 3, textAlign: activeId === "phone_in" ? "center" : "start" }}>
            <Typography.Paragraph className={"company-step"}>
              {analytics?.unix_time_action_happened ? formatUnixTimestamp(analytics?.unix_time_action_happened) : ""}
            </Typography.Paragraph>
            {analytics?.step_number && analytics?.campaign_name ? (
              <>
                {" "}
                <Typography.Paragraph
                  className={"emails-company-name"}
                  style={{ cursor: actCampId ? "pointer" : "none" }}
                  onClick={() => actCampId && navigate(`/campaign/${btoa(`${actCampId + 12000}`)}`)}
                >
                  {`Step ${analytics?.step_number} in`}{" "}
                  {fixCampName && fixCampName?.length > 25 ? (
                    <Button type={"link"} className={"company-link"}>
                      {" "}
                      <Tooltip placement={"right"} title={`Campaign name: ${fixCampName}`}>
                        {fixCampName?.substring(0, 25) + "..."}
                      </Tooltip>{" "}
                    </Button>
                  ) : (
                    <Button type={"link"} className={"company-link"}>
                      {" "}
                      {fixCampName}{" "}
                    </Button>
                  )}{" "}
                  {/* <Button type={"link"} className={"company-link"}>
                    {analytics?.campaign_name}
                  </Button> */}
                </Typography.Paragraph>
                {/* <Typography.Paragraph className={"company-step"}>
                  {`Step ${analytics?.step_number} in`}{" "}


                  <Button type={"link"} className={"company-link"}>
                    {analytics?.campaign_name}
                  </Button> 
                </Typography.Paragraph> */}
              </>
            ) : (
              ""
            )}
            {/* <Typography.Paragraph className={"company-step"}>
              {analytics?.unix_time_action_happened ? formatUnixTimestamp(analytics?.unix_time_action_happened) : ""}
            </Typography.Paragraph> */}
          </Col>
        );
      },
    },
    activeId !== "phone_in" && {
      title: "Status",
      // activeId === "phone_in" ? "Total Incoming Calls" : activeId === "good_call" ? "Status" : activeId === "bad_call" ? "Status" : "Total Calls",
      // dataIndex: "totalIncomingCalls",
      key: "totalIncomingCalls",
      align: "center",
      render: (record) => {
        console.log(record, "REcord");
        const analytics = record?.analyticToShow;
        const isSms = analytics?.dialer_action === "sms_recieved";
        return (
          <Col>
            {" "}
            <Typography.Paragraph className={"company-step"}>
              <b>{isSms ? "Total incoming:" : "Total calls:"}</b> {record?.count > 1 ? record?.count : "1"}
            </Typography.Paragraph>
            {activeId !== "phone_in" && analytics?.dialer_call_duration_seconds && analytics?.dialer_answered ? (
              <>
                <Typography.Paragraph className={"company-step"}>
                  {/* <Flex justify="center" align="center"> */} {/* <div style={{ marginRight: "3px", whiteSpace: "nowrap", overflow: "hidden" }}> */}
                  <b>Status:</b> {/* </div> */}
                  {analytics?.dialer_status_friendly ? analytics?.dialer_status_friendly : <MinusOutlined style={{ marginLeft: "3px" }} />} {/* </Flex> */}
                </Typography.Paragraph>
                <Typography.Paragraph className={"company-step"} style={{ margin: "auto 0" }}>
                  {/* <Flex justify="center" align="center"> */} {/* <div style={{ marginRight: "3px" }}> */} <b>Notes:</b> {/* </div> */}
                  {analytics?.dialer_call_notes ? (
                    analytics?.dialer_call_notes?.length > 25 ? (
                      <Tooltip placement={"right"} title={`Call notes: ${analytics?.dialer_call_notes}`}>
                        {analytics?.dialer_call_notes?.substring(0, 25) + "..."}
                      </Tooltip>
                    ) : (
                      analytics?.dialer_call_notes
                    )
                  ) : (
                    // <span style={{ marginTop: "50px" }}>
                    <MinusOutlined style={{ marginLeft: "3px" }} />
                    // </span>
                  )}
                  {/* </Flex> */}
                </Typography.Paragraph>
              </>
            ) : (
              ""
            )}
          </Col>
        );
      },
    },
    // {
    //   title: "Status",
    //   render: (record) => (
    //     <Row wrap={false}>
    //       {record?.status?.map((status: App.PhoneCallsStatus) => (
    //         <PhoneCallsStatus key={status} status={status} />
    //       ))}
    //     </Row>
    //   ),
    //   key: "status",
    //   align: "center",
    // },
  ]?.filter(Boolean);

  console.log(dataAll?.prospectsInRowsList, "dataAll?.prospectsInRowsList");

  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const handleChangePage = (pg) => {
    dispatch(
      getPhoneAnalytics({
        analyticToGet: activeId,
        page: pg,
        // pageSize,
        // dateRange,
        campaignId: dataAll?.campaignId,
      })
    );
    setPage(pg);
  };

  return (
    <Fragment>
      {/* <TableHeader searchPlaceholder={"Search..."} /> */}
      <Table
        customClassName={"phone-calls-table"}
        loading={!dataAll || loading}
        columns={columns}
        dataSource={dataAll?.prospectsInRowsList || []}
        pageSize={10}
        totalAmnt={dataAll.totalAmnt || 0}
        currentPage={page}
        setCurrentPage={handleChangePage}
        totalPages={dataAll.availPages || 1}
        quickDropdownSkip
        quickSkip
        locale={{
          emptyText: (
            <Empty
              image={<Image preview={false} src={NoData} alt={"No data"} width={173} height={150} />}
              imageStyle={{
                height: 180, // Ensure the image height is consistent with its actual height
                marginTop: "30px",
              }}
              description={<span style={{ marginTop: "10px" }}>No phone calls found.</span>} // Add margin to separate text from the image
            />
          ),
        }}
      />
    </Fragment>
  );
};
